import React, { createContext, useState, useContext, useEffect } from 'react';
import { apiConfig } from '../ApiConfig';
import { fetchData } from '../utils/helpers';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {

    const token = localStorage.getItem('token');

    const url = apiConfig.NETWORTH;
    const method = 'GET';
    const localStorageKey = 'networth-dashboard';

    const [sideBarData, setSideBarData] = useState(() => {
        // Initialize from localStorage
        const savedData = localStorage.getItem(localStorageKey);
        return savedData ? JSON.parse(savedData).header : null;
    });

    const loadSidebarData = async (update = false) => {
        try {
            const data = await fetchData(url, method, localStorageKey, update);
            if (data && data.header) {
                setSideBarData(data.header); // Update state with fetched data
            }
        } catch (error) {
            console.error("Failed to fetch sidebar data:", error);
        }
    };

    const updateSideBarData = async () => {
        try {
            console.log("Updating sidebar data...");
            await loadSidebarData(); // Ensure this fetches fresh data
        } catch (error) {
            console.error("Error updating sidebar data:", error);
        }
    }

    // useEffect(() => {
    //     if (token) {
    //         loadSidebarData();
    //     }
    // }, []); // Dependencies

    return (
        <SidebarContext.Provider value={{ sideBarData, setSideBarData, updateSideBarData }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => useContext(SidebarContext);
