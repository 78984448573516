import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { fetchData, removeSign } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import HoldingSummary from '../HoldingSummary';
import PieChart from '../PieChart';
import ApexChart from '../ApexChart';
import DynamicTable from '../DynamicTable';
import Analytics from '../Analytics';
import TrendingStocks from '../TrendingStocks';
import Loader from '../Loader'; // Import the loader component

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';

function USStocksAnalytics() {

  const location = useLocation();  // Get the current location
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const url = apiConfig.USANALYTICS;
  const method = 'GET';
  const localStorageKey = 'usStocksAnalyticsData';

  const [selectedChart, setSelectedChart] = useState('current');
  const [analyticsValues, setAnalyticsValues] = useState({}); 
  const [holdingInsights, setHoldingInsights] = useState({}); 
  const [distribution, setDistribution] = useState([]);
  const [topGainers, setTopGainers] = useState([]);
  const [topLosers, setTopLosers] = useState([]);
  const [baseDailyTrend, setBaseDailyTrend] = useState({}); 
  const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); 

  const [referenceTrend, setReferenceTrend] = useState(false);
  const [referenceDailyTrend, setReferenceDailyTrend] = useState(false); 
  const [referenceWeeklyTrend, setReferenceWeeklyTrend] = useState(false); 

  const [topGainersTime, setTopGainersTime] = useState('1d_change');
  const [topLosersTime, setTopLosersTime] = useState('1d_change');

  const [profitData, setProfitData] = useState([]);
  const [lossData, setLossData] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);

  // Fetch asset data when the component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        trackEvent('USStocks Analytics', 'request', 'Loading us stocks analytics data');
        const data = await fetchData(url, method, localStorageKey);
        if(data) updateStateWithData(data);
        trackEvent('USStocks Analytics', 'success', 'Successfully us stocks analytics data');
      } catch (error) {
        setError('Oops! Something went wrong. Please try again later.');
        trackEvent('USStocks Analytics', 'error', `APi (${url}): ${error.message}`);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  loadData();
  }, [url, method, localStorageKey]);
  
  const updateStateWithData = (result) => {
    // Assuming the API returns an object with asset values
    setAnalyticsValues(result);
    setHoldingInsights(result.holdings_insights);
    setTopGainers(result.holdings_summary.top_gainers);
    setTopLosers(result.holdings_summary.top_losers);
    setDistribution(result.holdings_insights.distribution);
    setProfitData(result.holdings_summary.profit_loss.profit);
    setLossData(result.holdings_summary.profit_loss.loss);

    const baseDailyTrend = result.historic_trend.base_trend.daily_data;
    setBaseDailyTrend(baseDailyTrend);
    const baseWeeklyTrend = result.historic_trend.base_trend.weekly_data;
    setBaseWeeklyTrend(baseWeeklyTrend);

    // setReferenceTrend(result.historic_trend.reference_trend);
    // const referenceDailyTrend = result.historic_trend.reference_trend.daily_data;
    // setReferenceDailyTrend(referenceDailyTrend);
    // const referenceWeeklyTrend = result.historic_trend.reference_trend.weekly_data;
    // setReferenceWeeklyTrend(referenceWeeklyTrend);

    trackEvent('USStocks Analytics', 'success', 'Loaded us stocks analytics data');
  }

  const pieDataProfitLoss = [
    ['Profit', removeSign(profitData[1]), removeSign(profitData[1])],
    ['Loss', removeSign(lossData[1]), removeSign(lossData[1])],
  ];

  const handleTopGainersChange = (e) => {
    setTopGainersTime(e.target.value);
    trackEvent('USStocks Analytics TopGainer', 'Change', `Change us stocks analytics topgainer ${e.target.value}`);
  };

  const handleTopLosersChange = (e) => {
    setTopLosersTime(e.target.value);
    trackEvent('USStocks Analytics TopLoser', 'Change', `Change us stocks analytics toploser ${e.target.value}`);
  };

  const memoizedTopGainers = useMemo(() => topGainers[topGainersTime] || [], [topGainers, topGainersTime]);
  const memoizedTopLosers = useMemo(() => topLosers[topLosersTime] || [], [topLosers, topLosersTime]);

  const renderTopGainers = useCallback(() => {
    return <TrendingStocks stocksData={memoizedTopGainers} />;
  }, [memoizedTopGainers]);

  const renderTopLosers = useCallback(() => {
    return <TrendingStocks stocksData={memoizedTopLosers} />;
  }, [memoizedTopLosers]);

  const initialRows = [
    {
      id: 'row1',
      columns: [
        { id: '1', header: 'Portfolio Summary', contentId: 'holding-summary'}
      ],
    },
    {
      id: 'row2',
      columns: [
        { id: '2', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
      ],
    },
    {
      id: 'row3',
      columns: [
        { id: '3', header: 'Market Cap', contentId: 'market-cap'},
        { id: '4', header: 'Sector Allocation', contentId: 'sector-distribution'},
      ]
    },
    {
      id: 'row4',
      columns: [
        { id: '5', header: 'Portfolio Breakdown by Company', contentId: 'distribution'},
        { id: '6', header: 'Unrealized Gain Breakdown by Company Holdings', contentId: 'unrealized-gain'},
      ]
    },
    {
      id: 'row5',
      columns: [
        { id: '6', header: 'Industry Distribution', contentId: 'industry-distribution'},
        { id: '7', header: 'Country Distribution', contentId: 'country-distribution'},
      ]
    },
    {
      id: 'row6',
      columns: [
        { id: '8', header: 'Portfolio Performance by Company Holdings', contentId: 'portfolio-performance'}
      ]
    },
    {
      id: 'row7',
      columns: [
        { id: '9', header: 'Top Gainers', contentId: 'trending-stocks-gainers'},
        { id: '10', header: 'Top Losers', contentId: 'trending-stocks-losers'}
      ]
    }
  ];

  const contentMap = useMemo(() => ({
    'holding-summary' : <HoldingSummary data={analyticsValues.holdings_summary} pieChartData={pieDataProfitLoss}/>,
    'historic-trend' : <div className='holding-chart-container'>
                          <ApexChart baseTrend={baseDailyTrend} referenceTrend={referenceDailyTrend} baseWeeklyTrend={baseWeeklyTrend} referenceWeeklyTrend={referenceWeeklyTrend} referenceIndex={false}/>
                        </div>,
    ...(analyticsValues.market_cap?.length > 0 && {
      'market-cap' : <PieChart pieData={analyticsValues.market_cap} id='chart3' width='480' height='250' />,
    }),
    ...(analyticsValues.sector_distribution?.length > 0 && {
      'sector-distribution' : <PieChart pieData={analyticsValues.sector_distribution} id='chart4' width='480' height='250' />,
    }),
    'distribution' : <div className="checkbox-heading">
                                    {/* <h2 className='heading'>Mutual Fund Distribution</h2> */}
                                    <div className='checkbox-wrapper'>
                                      <label className='analytics-label'>
                                        <input type='radio' className='analytics-checkbox' name='chartSelector' value='current' checked={selectedChart === 'current'} onChange={() => setSelectedChart('current')}/>
                                        Current
                                      </label>
                                      <label className='analytics-label'>
                                        <input type='radio'className='analytics-checkbox'  name='chartSelector' value='invested' checked={selectedChart === 'invested'} onChange={() => setSelectedChart('invested')} />
                                        Invested
                                      </label>
                                    </div>
                                  
                                    {selectedChart === 'current' && (
                                      <PieChart pieData={distribution.curr_val} id='current' width='480' height='250' />
                                    )}
                                    {selectedChart === 'invested' && (
                                      <PieChart pieData={distribution.investment} id='invested' width='480' height='250' />
                                    )}
                                  </div>,
    ...(holdingInsights.unrealised_gain?.length > 0 && {
      'unrealized-gain' : <div className="checkbox-heading">
                          <div className='checkbox-wrapper adjust-height'></div>
                          <PieChart pieData={holdingInsights.unrealised_gain} id='unrealized-gain' width='480' height='250' />
                        </div>,
    }),
    ...(analyticsValues.industry_distribution?.length > 0 && {
      'industry-distribution' : <PieChart pieData={analyticsValues.industry_distribution} id='industry-distribution' width='480' height='250' />,
    }),
    ...(analyticsValues.country_distribution?.length > 0 && {
      'country-distribution' : <PieChart pieData={analyticsValues.country_distribution} id='country-distribution' width='480' height='250' />,
    }),
    'portfolio-performance' : <div className="piechart-wrapper">
                                <DynamicTable tableData={holdingInsights.change}/>
                              </div>,
    'trending-stocks-gainers' : <div className='piechart-wrapper'>
                                  <select className='gainers-dropdown' onChange={handleTopGainersChange} value={topGainersTime}>
                                    <option value="1d_change">1 Day</option>
                                    <option value="1w_change">1 Week</option>
                                    <option value="1m_change">1 Month</option>
                                    <option value="total">Total</option>
                                  </select>
                                  {/* Conditionally render the trending stocks based on selected time period */}
                                  {renderTopGainers()}
                                </div>,
    'trending-stocks-losers' : <div className='piechart-wrapper'>
                                  <select className='gainers-dropdown' onChange={handleTopLosersChange} value={topLosersTime}>
                                    <option value="1d_change">1 Day</option>
                                    <option value="1w_change">1 Week</option>
                                    <option value="1m_change">1 Month</option>
                                    <option value="total">Total</option>
                                  </select>
                                  {/* Conditionally render the trending stocks based on selected time period */}
                                  {renderTopLosers()}
                                </div>,
   }), [analyticsValues, holdingInsights, distribution, baseDailyTrend, baseWeeklyTrend, referenceDailyTrend, referenceWeeklyTrend, selectedChart, topGainersTime, topLosersTime, pieDataProfitLoss]);
  
  return (
    <div className='us-stock-analytics'>
      {isLoading ? (
          <Loader /> // Show the loader while data is loading
      ) : error ? (
        <div className='show-error'>{error}</div>
      ) : (
        <Analytics initialRows={initialRows} contentMap={contentMap} module={'usstocks-analytics'}/>
      )}
    </div>
  );
}

export default USStocksAnalytics;
