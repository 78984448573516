import React from 'react';
import './css/TermsAndCondition.css';
import { useAuth } from './AuthContext';
import HeaderNoValidation from './components/HeaderNoValidation';
import Header from './components/Header';

const PrivacyPolicy = () => {
    const {isAuthenticated} = useAuth();

    return (
        <>
            {isAuthenticated ? <Header /> : <HeaderNoValidation />}
            <div className='container termsOfServices'>
                {/* <h5>Last Updated 3-Nov-2022</h5> */}
                <h1 className='heading-name'>Privacy Policy</h1>
                <div className='card'>
                    <div className='card-body p-3'>
                        <div className='p-sm-4'>
                            <br/>
                            <p>Welcome to MoneyLens. We are committed to protect your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.moneylens.in, use our services, and interact with us.</p>
                            
                            <h4><b style={{fontSize: '16px'}}>INFORMATION WE COLLECT</b></h4>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li><b>Personal Information:</b> We may collect personal information such as your name, email address, phone number, and financial details when you register, subscribe, or interact with our services.</li>
                                <li><b>Financial Data:</b> We collect data related to your investments, assets, and liabilities to provide insights and track your net wealth.</li>
                                <li><b>Usage Data:</b> We automatically collect information about your interactions with our website, including your IP address, browser type, operating system, pages visited, and the time and date of your visit.</li>
                            </ul>
                            
                            <h4><b style={{fontSize: '16px'}}>HOW WE USE YOUR INFORMATION</b></h4>
                            <p>We use the information we collect to:</p>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li>Provide, operate, and maintain our services.</li>
                                <li>Improve, personalize, and expand our services.</li>
                                <li>Understand and analyze how you use our services.</li>
                                <li>Develop new products, services, features, and functionality.</li>
                                <li>Communicate with you, including customer service, updates, and promotional materials.</li>
                                <li>Process your transactions and manage your account.</li>
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>SHARING YOUR INFORMATION</b></h4>
                            <p>We do not sell, trade, or otherwise transfer your personal information to third parties except in the following circumstances:</p>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li><b>Service Providers:</b> We may share your information with third-party service providers who perform services on our behalf, such as payment processing.</li>
                                <li><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
                                <li><b>Business Transfers:</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>YOUR DATA PROPTECTION RIGHTS</b></h4>
                            <p>Depending on your location, you may have the following rights regarding your personal information:</p>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li>The right to access – You have the right to request copies of your personal information.</li>
                                <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
                                <li>The right to erasure – You have the right to request that we erase your personal information, under certain conditions.</li>
                                <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
                                <li>The right to object to processing – You have the right to object to our processing of your personal information, under certain conditions.</li>
                                <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>CHANGES TO THIS PRIVACY POLICY</b></h4>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;