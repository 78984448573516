import React, { useState, useEffect } from 'react';
import '../css/DynamicTabs.css';

function DynamicTabs({ tabs, data, module, onTabChange  }) {
  // Set initial active tab based on the hash in the URL or default to the first tab
  const getInitialTab = () => {
    const hash = window.location.hash.substring(1); // Get the hash without '#'
    return tabs.find(tab => tab.key === hash)?.key || tabs[0].key; // Return tab key or default to first tab
  };

  const [activeTab, setActiveTab] = useState(getInitialTab);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      const matchedTab = tabs.find((tab) => tab.key === hash)?.key || tabs[0].key;
      if (matchedTab !== activeTab) {
        setActiveTab(matchedTab);
        if (onTabChange) {
          onTabChange(matchedTab); // Notify parent on tab change
        }
      }
      // setActiveTab(tabs.find(tab => tab.key === hash)?.key || tabs[0].key);
    };

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [tabs]);

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
    window.location.hash = tabKey; // Update the hash in the URL
    if (onTabChange) {
      onTabChange(tabKey); // Notify parent about tab change
    }
  };

  const renderTabContent = () => {
    const activeTabData = tabs.find(tab => tab.key === activeTab);
    if (activeTabData) {
      const Component = activeTabData.component;
      return <Component data={data} module={module}/>;
    }
    return null;
  };

  return (
    <div className="col-xs-12 col-sm-12 col-md-12">
      <ul className="nav nav-tabs" role="tablist">
        {tabs.map((tab) => (
          <li
            role="presentation"
            className={activeTab === tab.key ? 'active' : 'tabLi'}
            key={tab.key}
          >
            <a
              href={`#${tab.key}`}
              onClick={() => handleTabClick(tab.key)}
              role="tab"
              data-toggle="tab"
            >
              {tab.name}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default DynamicTabs;
