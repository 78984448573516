import React, {useState, useEffect} from 'react';
import { Button, Form } from 'react-bootstrap';
import './css/Login.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFullUrl, setCookie, apiRequest } from './utils/helpers';
import { apiConfig } from './ApiConfig';
import { trackPageView, trackEvent } from './utils/googleAnalytics';
import Footer from './Footer';

const ResetPassword = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);  // Clear previous error
        setIsButtonLoading(true);
        const form = e.currentTarget;
    
        // Check if form is valid
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
            return;
        }
        setValidated(true);
        try {
            const formData = {
                email: email
            };
            
            // Track event: Form submission attempt
            trackEvent('User Actions', 'Submit Reset Password Form', 'Email: ' + email);

            // Call the API to post the form data
            const result = await apiRequest(apiConfig.SENDOTP, 'POST', formData);
            
            if (result.statusCode === 200) {
                setCookie('emailVerify', email, 7);
                // Track event: Success event
                trackEvent('User Actions', 'Request OTP Success', 'Email: ' + email);
                navigate('/setpassword')
            } else if (result.statusCode === 401 || result.statusCode === 400) {
                setError(result.message);
                // Track event: Error event
                trackEvent('User Actions', 'Request OTP Error', result.message);
            }
        } catch (error) {
            // Display the API error message
            setError('Oops! Something went wrong. Please try again later.');
            // Track event: Exception event
            trackEvent('User Actions', 'Request OTP Error', error.message);
        } finally {
            setIsButtonLoading(false);
        }            
    };

    return (
        <div className='login-main-margin'>
            <div className='login-main-container'>
                <div className="sign-panels">
                    <div className="login">
                        <div className='logo-container'>
                            <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "3rem", height: "3rem" }} />
                            <div className='logo-name'>MoneyLens</div>
                        </div>
                        <div className="title">
                            <span>Reset Password</span>
                            <p className='sub-title'>Enter your registered email address</p>
                        </div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            {/* Email input */}
                            <Form.Group controlId="email" className='password-container'>
                                <Form.Control
                                    type="email"
                                    placeholder="Email Address *"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    autoComplete="off"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter your email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {error && <div className="error-message">{error}</div>}

                            {/* Submit Button */}
                            <Button className="btn-signin" type="submit" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Requesting...' : 'Request OTP'}
                            </Button>

                            <span onClick={() => navigate('/login')} className="btn-login btn-fade"><i className="fa fa-long-arrow-left" aria-hidden="true"></i>Go back to Sign In page</span>
                        </Form>
                    </div>
                </div>
                <div className='right-container'>
                    <div className='right-top'>
                        <div>Welcome Back!</div>
                        <div className='quotes'>Your wealth insights,<br />
                        just a click away.</div>
                    </div>
                    <img src={getFullUrl('images/banner.png')} alt="banner" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResetPassword;
