import React, { useState, useEffect } from "react";
import '../css/Assets.css';
import { useNavigate } from 'react-router-dom';
import { fetchData, getFullUrl, shortFormatRupees } from '../utils/helpers';
import { apiConfig } from "../ApiConfig";
//import Loader from './Loader'; // Import the loader component


function Assets() {

    const navigate = useNavigate();

    const [assetValues, setAssetValues] = useState({}); // State to hold asset values
    // Fetch asset data when the component mounts
    useEffect(() => {
        const loadAssetsData = async () => {
            try {
                const urls = {
                    mutualFundList: { url: apiConfig.MFSUMMARY, key: 'mutualFundList' },
                    indiaStockList: { url: apiConfig.INDIASTOCKSUMMARY, key: 'indiaStockList' },
                    usStockList: { url: apiConfig.USSUMMARY, key: 'usStockList' },
                    privateEquityList: { url: apiConfig.PRIVATEEQUITYSUMMARY, key: 'privateEquityList' },
                    cryptoCurrencyList: { url: apiConfig.CRYPTOCURRENCYSUMMARY, key: 'cryptoCurrencyList' },
                    realEstateList: { url: apiConfig.REALESTATESUMMARY, key: 'realEstateList' },
                    preciousMetalsList: { url: apiConfig.PRECIOUSMETALSSUMMARY, key: 'preciousMetalsList' },
                };
        
                const assetValues = {};
        
                for (const [key, { url, key: storageKey }] of Object.entries(urls)) {
                    try {
                        const fetchedValue = await fetchData(url, 'GET', storageKey, false);
                        assetValues[key] = fetchedValue;
        
                        // Update state incrementally after each fetch
                        setAssetValues((prevState) => ({
                            ...prevState,
                            [key]: fetchedValue,
                        }));
                    } catch (error) {
                        console.error(`${key.toUpperCase()} Assets:`, error);
                    }
                }
                // Optional: Update the state with all values at once (if needed)
                setAssetValues(assetValues);
            } catch (error) {
                console.error('Assets data:', error);
            }
        };
        

        loadAssetsData();
    }, []);

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/mutualfund')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-chart-bar icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Mutual Funds</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.mutualFundList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/indiastocks')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-rupee-sign icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">India Stocks</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.indiaStockList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/usstocks')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-dollar-sign icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">US Stocks</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.usStockList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/privateequity')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className="fas fa-balance-scale-left icon"></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Private Equity</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.privateEquityList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/cryptocurrency')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <img alt="icon" src={getFullUrl('images/crypto.svg')} className="icon crypto-icon" />
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Crypto Currency</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.cryptoCurrencyList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/realestate')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-building icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Real Estate</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.realEstateList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/preciousmetals')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-gem icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Precious Metals</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues?.preciousMetalsList?.curr_val) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/fds')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-landmark icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">FDs</div>
                            <div className="floating-value" style={{color: '#ced4da'}}>{`(Coming Soon)`}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-car-alt icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Vehicle</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.vehicle) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-euro-sign icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">EPF</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.epf) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-piggy-bank icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">PPF</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.ppf) || 'Loading...'}</div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row justify-content-center">
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/retirementfunds')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-child icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Retirement Funds</div>
                            <div className="floating-value" style={{color: '#ced4da'}}>{'(Coming Soon)'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center" onClick={() => handleNavigation('/assets/others')}>
                    <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-shapes icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Others</div>
                            <div className="floating-value" style={{color: '#ced4da'}}>{'(Coming Soon)'}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center">
                    {/* <div className="asset-container">
                        <div className="icon-container">
                            <div className="icon-3d">
                                <i className='fas fa-wallet icon'></i>
                            </div>
                        </div>
                        <div className="card-content">
                            <div className="title">Cash</div>
                            <div className="floating-value">₹{shortFormatRupees(assetValues.cash) || 'Loading...'}</div>
                        </div>
                    </div> */}
                </div>
                <div className="col-xs-6 col-sm-6 col-md-3 text-center"></div>
            </div>
        </div>
    );
    
}

export default Assets;