import React, { useState, useRef, useEffect } from "react";
import '../../css/PreciousMetalsDetails.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate, fetchData, updateDirtyState, isEmptyField, sortData, apiRequest, getFullUrl, shortFormatRupees, showErrorMessage } from "../../utils/helpers";
import Loader from "../Loader";
import EmptyState from "../EmptyState";
import PreciousMetalsForm from "./PreciousMetalsForm";
import { apiConfig } from "../../ApiConfig";

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';

// import { useSidebar } from "../SidebarContext";

function PreciousMetalsDetails() {

    const location = useLocation();

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true);
    const [metalCode, setMetalCode] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
    const [activeSortKey, setActiveSortKey] = useState(null);

    const [editingRowId, setEditingRowId] = useState(null); // Track the row being edited
    const [metalsDetails, setMetalsDetails] = useState([
        {
            id: 1,
            metalsName: 'Gold',
            investedAmount: '10,029,00', // Field for the invested amount
            currentValue: '10,029,00',
            weight: '100', // Field for the weight in grams
            returns: '10',
            investedDate: '1724371200000', // Field for the date of investment
        },
        {
            id: 2,
            metalsName: 'Gold',
            investedAmount: '5,000,00', // Field for the invested amount
            currentValue: '5,000,00',
            weight: '50', // Field for the weight in grams
            returns: '5',
            investedDate: '1724371200000', // Field for the date of investment
        }
        // Add more details as needed
    ]);

    const [error, setError] = useState(''); // State for error message

    const inputRefs = useRef({}); // Create a ref object to store input references
    
    // const { updateSideBarData } = useSidebar();
    
    const url  = `${apiConfig.PRECIOUSMETALSTRANSACTIONSINFO}/${metalCode}`;

    const metalsIcon = {
        au: getFullUrl('images/gold.png'),
        ag: getFullUrl('images/silver.png'),
        pd: getFullUrl('images/palladium.png'),
        pt: getFullUrl('images/platinum.png')
    }

    useEffect(() => {
        const parts = location.pathname.split('/').filter(Boolean);
        const metalCode = parts[parts.length - 1];
        setMetalCode(metalCode);
    }, [location]);
    
    useEffect(() => {
        if (metalCode && !isEmptyField(metalCode)) {
            loadData();
        }
    }, [metalCode]);

    useEffect(() => {
        // Focus the first editable cell when editing starts
        if (editingRowId !== null && inputRefs.current[editingRowId]) {
            inputRefs.current[editingRowId].focus();
        }
    }, [editingRowId]);

    const loadData = async () => {
        try {
            trackEvent('PreciousMetals Info', 'request', `Loading data for ${metalCode}`);
            const data = await fetchData(url, 'GET', '', true, true);
            if (data) {
                setTransactions(data.transactions);
                trackEvent('PreciousMetals Info', 'Success', `Loaded data for ${metalCode}`);
            } else {
                // Set empty state or handle missing data case
                setTransactions([]);
                trackEvent('PreciousMetals Info', 'Failure', `No data found for ${metalCode}`);
            }
        } catch (error) {
            showErrorMessage();
            console.error(`Precious Metals Info: ${error.message}`);
            trackEvent('PreciousMetals Info', 'Error', `Error loading data for ${metalCode}: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEdit = (transaction) => {
        setCurrentTransaction({...transaction});
        setShowModal(true);
    };

    const handleInputChange = (e, id, field) => {
        const newDetails = metalsDetails.map(details =>
            details.id === id ? { ...details, [field]: e.target.textContent } : details
        );
        setMetalsDetails(newDetails);
    };

    const handleRefresh = () => {
        setIsLoading(true);
        loadData();
        updateDirtyState('preciousMetalsList');
        updateDirtyState('preciousMetalsAnalyticsData');
        updateDirtyState('preciousMetalsTransactionList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();

        trackEvent('PreciousMetals Action', 'Refresh', `Refreshed data for ${metalCode}`);
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentTransaction(null);
    };

    const handleFormSubmit = async (formData) => {
        formData = formData[0];
        const id = formData.id;
        try {
            trackEvent('PreciousMetals Transactions', 'update request', `Loading data for ${id}`);
            const url = `${apiConfig.PRECIOUSMETALSTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'PUT', formData);
            if(result.statusCode === 200){
                handleRefresh();
                handleCloseModal(); // Close the modal after submission
                trackEvent('PreciousMetals Transactions', 'Success', `Loaded data for ${id}`);
            }else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('PreciousMetals Transactions', 'Error', `Error loading data for ${id}: ${result.message}`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('PreciousMetals Transactions', 'Error', `Error loading data for ${id}: ${error.message}`);
        }
    };

    const handleDelete = async (id) => {
        const confirmClose = window.confirm('Are you sure you want to delete?');
        if (!confirmClose) {
            return; // Prevent closing if user cancels
        }
        try {
            trackEvent('PreciousMetals Transactions', 'delete request', `Loading data for ${id}`);
            const url = `${apiConfig.PRECIOUSMETALSTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'DELETE', null);
            if(result.statusCode === 200){
                handleRefresh();
                trackEvent('PreciousMetals Transactions', 'Success', `Loaded data for ${id}`);
            }else {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('PreciousMetals Transactions', 'Error', `Error loading data for ${id}: ${result.message}`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('PreciousMetals Transactions', 'Error', `Error loading data for ${id}: ${error.message}`);
        }
    }

    // Function to handle sorting logic
    const sortedData = sortData(transactions, sortConfig);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
        }
        setSortConfig({ key, direction });
        setActiveSortKey(key); // Set the active sort key
    };
    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : transactions.length === 0 ? (
                <EmptyState openModal={false} backeUrl="/assets/preciousmetals"/>
            ) : (
            <div className="scheme-info-details-container company-info-container precious-metals-details container">
                <div className="icon-inner" onClick={() => handleNavigation('/assets/preciousmetals')} style={{paddingLeft: 'unset'}}>
                    <img alt="leftArrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/back-black-icon.png')}/>
                    <div className='module-name' style={{color: '#000000'}}>Back</div>
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Metals</th>
                                <th scope="col" className={`filter ${activeSortKey === 'investment' ? 'active' : ''}`} onClick={() => handleSort('investment')}>Investment <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                                <th scope="col" className={`filter ${activeSortKey === 'weight' ? 'active' : ''}`} onClick={() => handleSort('weight')}>Weight <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                                <th scope="col" className={`filter ${activeSortKey === 'date' ? 'active' : ''}`} onClick={() => handleSort('date')}>Date <i className="fas fa-caret-down" aria-hidden="true"></i></th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map(details => (
                                <tr key={details.id}>
                                    <th scope="row">
                                        <img loading="lazy" width="18" height="18" decoding="async" data-nimg="1" src={metalsIcon[details.metal_code]} alt='icon'/>
                                    </th>
                                    <td>
                                        {details.name}
                                    </td>
                                    <td
                                        onChange={(e) => handleInputChange(e, details.id, 'investedAmount')}
                                    >
                                        ₹{shortFormatRupees(details.investment)}
                                    </td>
                                    <td
                                        onChange={(e) => handleInputChange(e, details.id, 'weight')}
                                    >
                                        {details.weight} gm
                                    </td>
                                    <td
                                        onChange={(e) => handleInputChange(e, details.id, 'investedDate')}
                                    >
                                        {formatDate(parseInt(details.date))}
                                    </td>
                                    <td className="actions-container">
                                        <i className='fas fa-edit edit-company' onClick={() => handleEdit(details)}></i>
                                        <i className='fas fa-trash delete-company' onClick={() => handleDelete(details.id)}></i>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
            </div>
            )}
            {showModal &&
            <PreciousMetalsForm 
                isOpen={showModal} 
                closeModal={handleCloseModal} 
                onFormSubmit={handleFormSubmit}
                filledData={currentTransaction}
                onEdit={true}
            />

            }
        </div>
    );
}

export default PreciousMetalsDetails;
