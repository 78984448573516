import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { apiConfig } from '../../ApiConfig';
import { fetchData, shortFormatRupees, isNegative, updateDirtyState, removeSign, getFullUrl, formatNumberWithTwoDecimals, showErrorMessage } from '../../utils/helpers';
import { message } from 'antd';
import '../../css/CompanyInformation.css';

import Loader from '../Loader';
import EmptyState from '../EmptyState';
import ApexChart from '../ApexChart';
import PieChart from '../PieChart';
import Analytics from '../Analytics';
import MFIndividualTransactionList from './MFIndividualTransactionList';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';

// import { useSidebar } from '../SidebarContext';

function MFInformation() {
    const { id } = useParams();
    const location = useLocation();
    const [pageInfo, setPageInfo] = useState('');

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true);
    const [information, setInformation] = useState([]);
    const [info1DayChange, setInfo1DayChange] = useState([]);
    const [baseTrend, setBaseTrend] = useState([]);
    const [referenceTrend, setReferenceTrend] = useState([]);
    const [portfolioInsights, setPortfolioInsights] = useState([]);
    const [transactions, setTransactions] = useState([]);
    // const { updateSideBarData } = useSidebar();

    const url = `${apiConfig.MFINFO}/${id}`;
    const method = 'GET';

    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url]);

    useEffect(() => {
        const parts = location.pathname.split('/').filter(Boolean);
        const pageInfo = parts[parts.length - 3];
        setPageInfo(pageInfo);
    }, [location.pathname]);

    const loadData = async () => {
        try {
            trackEvent('MF Info', 'request', `Loading data for ${id}`);
            const data = await fetchData(url, method, '', true, true);
            if (data) {
                setInformation(data.header);
                setInfo1DayChange(data.header['1d_change']);
                setBaseTrend(data.historic_trend.base_trend);
                setReferenceTrend(data.historic_trend.reference_trend);
                setPortfolioInsights(data.portfolio_insights);
                setTransactions(data.transactions);
                trackEvent('MF Info', 'Success', `Loaded data for ${id}`);
            } else {
                // Set empty state or handle missing data case
                setInformation([]);
                setInfo1DayChange([]);
                setBaseTrend([]);
                setReferenceTrend([]);
                setPortfolioInsights([]);
                setTransactions([]);
                trackEvent('MF Info', 'Failure', `No data found for ${id}`);
            }
        } catch (error) {
            showErrorMessage();
            console.error(`MF Info: ${error.message}`);
            trackEvent('MF Info', 'Error', `Error loading data for ${id}: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        setIsLoading(true);
        loadData();
        updateDirtyState('mutualFundList');
        updateDirtyState('mutualFundAnalyticsData');
        updateDirtyState('mutualFundTransactionList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();
        trackEvent('MF Action', 'Refresh', `Refreshed data for ${id}`);
    }
    const returnsPercentage = isNegative(parseInt(info1DayChange[0]));
    const profitLossSymbol = (returnsPercentage) ? '' : '+';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';
    const rotateClass = (returnsPercentage) ? 'rotate-text' : '';

    const initialRows = [
        {
          id: 'row1',
          columns: [
            { id: '1', header: 'Company Allocation', contentId: 'company-distribution'},
            { id: '2', header: 'Market Cap', contentId: 'market-cap'},
          ],
        },
        {
          id: 'row2',
          columns: [
            { id: '3', header: 'Industry Allocation', contentId: 'industry-distribution'},
            { id: '4', header: 'Rating Allocation', contentId: 'rating-distribution'},
          ]
        },
        {
          id: 'row3',
          columns: [
            { id: '5', header: 'Type Allocation', contentId: 'type-distribution'},
            { id: '6', header: 'Sector Allocation', contentId: 'sector-distribution'},
          ]
        }
    ];
    
    const contentMap = useMemo(() => ({
        ...(portfolioInsights.company_distribution?.length > 0 && {
            'company-distribution': <PieChart pieData={portfolioInsights.company_distribution} id="company_distribution" width="480" height="250" />
        }),
        ...(portfolioInsights.market_cap_class?.length > 0 && {
            'market-cap': <PieChart pieData={portfolioInsights.market_cap_class} id="market_cap_class" width="480" height="250" />
        }),
        ...(portfolioInsights.industry_distribution?.length > 0 && {
            'industry-distribution': <PieChart pieData={portfolioInsights.industry_distribution} id="industry_distribution" width="480" height="250" />
        }),
        ...(portfolioInsights.rating_distribution?.length > 0 && {
            'rating-distribution': <PieChart pieData={portfolioInsights.rating_distribution} id="rating_distribution" width="480" height="250" />
        }),
        ...(portfolioInsights.sector_distribution?.length > 0 && {
            'sector-distribution': <PieChart pieData={portfolioInsights.sector_distribution} id="sector_distribution" width="480" height="250" />
        }),
        ...(portfolioInsights.type_distribution?.length > 0 && {
            'type-distribution': <PieChart pieData={portfolioInsights.type_distribution} id="type_distribution" width="480" height="250" />
        })
    }), [portfolioInsights]);
    
    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : transactions.length === 0 ? (
                <EmptyState openModal={false} backeUrl='/assets/mutualfund'/>
            ) : (
                <div className='company-info-container container'>
                    <div className="icon-inner" onClick={() => handleNavigation('/assets/mutualfund')} style={{paddingLeft: 'unset'}}>
                        <img alt="leftArrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/back-black-icon.png')}/>
                        <div className='module-name' style={{color: '#000000'}}>Back</div>
                    </div>
                    <div className="company-info-heading-container">
                        <div className='company-info-heading-img-container'>
                            <img
                                alt=""
                                loading="lazy"
                                width="75"
                                height="75"
                                decoding="async"
                                src={information.logo}
                                style={{ color: 'transparent' }}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop in case the fallback also fails
                                    e.target.style.display = 'none'; // Removes image space when the image fails to load
                                }}
                            />
                        </div>
                        <div className="company-info-heading-name-container">
                            <h3>{information.name}</h3>
                            <div className='company-info-heading-price-container'>
                                <span className="this-fund-col">
                                    Current Value:&nbsp;
                                    <span className="company-info-heading-price">
                                        ₹{shortFormatRupees(information.curr_val)} (Qty: {Math.round(information.quantity)}, NAV: {formatNumberWithTwoDecimals(information.curr_price)})
                                    </span>
                                </span>
                                <span className="this-fund-col">
                                    1 Day Change:&nbsp;
                                    <span className="company-info-graph-profit-number text-bold">₹{shortFormatRupees(info1DayChange[0])}&nbsp;&nbsp;
                                        <span className={classNames('', profitLossClass)}>
                                            <span className={classNames('', rotateClass)}>▲</span>
                                            {removeSign(info1DayChange[1])}%
                                        </span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='trend-container'>
                        <ApexChart
                            baseTrend={baseTrend.daily_data}
                            referenceTrend={referenceTrend.daily_data}
                            baseWeeklyTrend={baseTrend.weekly_data}
                            referenceWeeklyTrend={referenceTrend.weekly_data}
                            referenceIndex={referenceTrend.index}
                            baseIndex='NAV'
                        />
                    </div>
                    <Analytics initialRows={initialRows} contentMap={contentMap} module={'mf-info-analytics'}/>
                    <MFIndividualTransactionList transaction={transactions} sceheme_code={id} handleRefresh={handleRefresh}/>
                </div>
            )}
        </div>
    );
}

export default MFInformation;
