import React from 'react';
import '../css/TotalPortfolioValue.css';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getFullUrl, isNegative, removeSign, shortFormatRupees } from '../utils/helpers';

function TotalPortfolioValue ({data, onRefresh, module, openForm }) {
    
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };
    const returnsOneDayPercentage = isNegative(parseFloat(data.dayChangeValue));
    const rotateOneDayClass = (returnsOneDayPercentage) ? 'rotate-text' : '';
    const profitLossOneDayClass = (returnsOneDayPercentage) ? 'loss-color' : 'profit-color';

    const lastRowClass = (module != 'India Stocks' && module !== 'NetWorth' ) ? '' : 'margin-btm';

    const returnsPercentage = isNegative(parseFloat(data.returns));
    const rotateReturnsClass = (returnsPercentage) ? 'rotate-text' : '';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';

    return (
        <div className="col-xs-12 col-sm-12 col-md-12 bg-header-container">
            <div className="bg-inner">
                <div className="icon-section">
                    <div className="icon-inner" onClick={() => handleNavigation('/assets')}>
                        <img alt="leftArrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/back-grey-icon.png')}/>
                        <div className='module-name'>{module}</div>
                    </div>
                    {/* <div className="icon-inner2">
                        <img alt="Info" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/info.png')}/>
                    </div> */}
                </div>
                <div className="main-section">
                    <p className="main-section-p">Total Portfolio Value</p>
                    <div className="price-section">
                        <h3 className="price">₹{shortFormatRupees(data.value)}</h3>
                        <img alt="Refresh" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/refresh.png')} onClick={onRefresh}/>
                        {data.plusIcon && (
                            <img alt="Add" loading="lazy" width="36" height="36" decoding="async" data-nimg="1" className="margin-left-plus-icon" src={getFullUrl('images/plus.png')} title={data.plusIconTitle} onClick={openForm}/>
                        )}
                    </div>
                    {data.dayChangeValue && (
                        <div className={classNames('main-section-sub-p', lastRowClass)}>
                            <span className="main-section-sub-span">1 Day Change: </span>
                            <span className={classNames("main-section-sub-span", profitLossOneDayClass)}>
                                ₹{shortFormatRupees(data.dayChangeValue)}
                            </span>
                            <span className={classNames("main-section-sub-span", profitLossOneDayClass)}>
                                <span className={classNames('', rotateOneDayClass)}>▲</span>
                                {removeSign(data.percentage)}%
                            </span>
                        </div>
                    )}
                    {(module != 'India Stocks' && module !== 'NetWorth') && (
                    <div className="main-section-sub-p total-invested">
                        <div className="main-section-sub-span marging-right-title title-color title-invested">
                            <span>Invested</span> 
                            <span className='subtitle-color'>₹{shortFormatRupees(data.invested)}</span>
                        </div>
                        <div className="main-section-sub-span marging-right-title title-color title-invested">
                            <span>Returns</span> 
                            <span className='subtitle-color'>₹{shortFormatRupees(data.returns)}</span>
                        </div>
                        <div className="main-section-sub-span marging-right-title title-color title-invested">
                            <span>Returns %</span> 
                            <span className={classNames('subtitle-color', profitLossClass)}>
                                <span className={classNames('', rotateReturnsClass)}>▲</span>
                                {removeSign(data.returnspercent)}%
                            </span>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default TotalPortfolioValue;