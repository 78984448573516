import React, { useState, useEffect } from "react";
import { isNegative, removeSign, shortFormatRupees } from '../utils/helpers';
import classNames from 'classnames';
import "../css/TrendingStocks.css"; // Import the CSS file for styling

const TrendingStocks = ({ stocksData = [] }) => {
  const [logos, setLogos] = useState(stocksData);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const [progress, setProgress] = useState(0); // Progress state for the circular progress bar

  useEffect(() => {
    // Guard clause to prevent errors if stocksData is empty
    if (stocksData.length === 0) return;

    // Initialize the logos state based on stocksData
    setLogos(stocksData);

    // Set up the progress interval
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 70) {
          // If progress is complete, move the current logo to the end of the array
          setLogos((prevLogos) => {
            const newLogos = [...prevLogos];
            const movedLogo = newLogos.shift(); // Remove the first logo
            newLogos.push(movedLogo); // Add it to the end
            return newLogos; // Return the new logos order
          });
          setCurrentLogoIndex(0); // Reset to the first logo in the new order
          return 0; // Reset progress
        }
        return prevProgress + 1; // Increment progress
      });
    }, 100); // Adjust speed of progress (100ms for smooth progress)

    // Cleanup interval on component unmount
    return () => clearInterval(progressInterval);
  }, [stocksData]);

  // If stocksData is empty, return null (or show a loading state if needed)
  if (stocksData.length === 0) {
    return <p className="trendingStocks-empty">No data available.</p>; // Optional: display a message when there are no stocks
  }

  // Ensure currentLogoIndex is within bounds
  const activeLogo = logos[currentLogoIndex] || logos[0]; // Fallback to the first stock if index is invalid

  // Check if activeLogo and its returns property are valid
  const activeReturns = activeLogo && activeLogo.returns ? activeLogo.returns : [0, 0]; // Ensure there's a default value

  // Only filter other logos if there are more than one stock
  const otherLogos = logos.length > 1 ? logos.filter((_, index) => index !== currentLogoIndex) : [];

  // Determine if the returns percentage is negative
  const isLoss = isNegative(parseFloat(activeReturns[0]));
  const rotateClass = isLoss ? 'rotate-text' : '';
  const profitLossClass = isLoss ? 'loss-color' : 'profit-color';

  // Function to handle clicks on remaining logos
  const handleLogoClick = (index) => {
    setLogos((prevLogos) => {
      const newLogos = [...prevLogos];
      // Move the clicked logo to the end of the list
      const [clickedLogo] = newLogos.splice(index, 1); // Remove the clicked logo
      newLogos.push(clickedLogo); // Add it to the end

      return newLogos; // Return the new logos order
    });
    setCurrentLogoIndex(logos.length - 1); // Set the current index to the last logo (newly active logo)
    setProgress(0); // Reset the progress bar when a logo is clicked
  };

  return (
    <div className="slider-container">
      <div className="slider-content">
        <div className="logos">
          <div className="logo-item active">
            <div className="progress-circle">
              <svg className="progress-svg" viewBox="0 0 40 40" preserveAspectRatio="xMidYMid meet">
                <circle
                  className="progress-circle-background"
                  cx="20"
                  cy="20"
                  r="18"
                />
                <circle
                  className={classNames('progress-circle-bar', profitLossClass)}
                  cx="20"
                  cy="20"
                  r="18"
                  style={{ strokeDashoffset: 190 - (190 * progress) / 100 }} // Adjusted for the new radius
                />
              </svg>

              {activeLogo && (
                <img src={activeLogo.logo} alt={activeLogo.symbol} className="logo-image" />
              )}
            </div>
            <svg
              id="triangle"
              viewBox="0 0 40 40"
              className={classNames('down-arrow fill-brand-blue-azureRadiance', profitLossClass)}
            >
              <polygon points="20 15, 40 40, 0 40"></polygon>
            </svg>
          </div>
          {otherLogos.map((logo, index) => (
            <div
              key={logo.id}
              className="logo-item"
              onClick={() => handleLogoClick(logos.findIndex(item => item.id === logo.id))} // Handle click event
              style={{ cursor: 'pointer' }} // Add pointer cursor for better UX
            >
              <img src={logo.logo} alt={logo.name} className="logo-image" />
            </div>
          ))}
        </div>
        <div className="logo-details">
          <p className="fund-name">{activeLogo?.name}</p>
          <div className="fund-price-container">
            <p className="fund-price">₹{shortFormatRupees(activeReturns[0])}</p>
            <p className={classNames('', profitLossClass)}>
              <span className={classNames('', rotateClass)}>▲</span>{removeSign(activeReturns[1])}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingStocks;
