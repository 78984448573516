import React from 'react';
import './css/TermsAndCondition.css';
import { useAuth } from './AuthContext';
import HeaderNoValidation from './components/HeaderNoValidation';
import Header from './components/Header';

const TermsAndCondition = () => {
    const {isAuthenticated} = useAuth();

    return (
        <>
            {isAuthenticated ? <Header /> : <HeaderNoValidation />}
            <div className='container termsOfServices'>
                {/* <h5>Last Updated 3-Nov-2022</h5> */}
                <h1 className='heading-name'>Terms and Conditions</h1>
                <div className='card'>
                    <div className='card-body p-3'>
                        <div className='p-sm-4'>
                            <br/>
                            <p>Welcome and thank you for choosing MoneyLens! By accessing and using the MoneyLens platform, you agree to be bound by the Terms and Conditions outlined below, along with our Privacy Policy and any additional terms specific to the products and/or services you access. We highly recommend that you take a moment to review and familiarize yourself with these Terms and Conditions.</p>
                            <p><i>Note: By registering, browsing, accessing, downloading, and/or using the MoneyLens Platform, either for general or specific purposes, you are agreeing to and entering into a legally binding agreement with MoneyLens Private Limited and its affiliates.</i></p>
                            <p><b>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THE MoneyLens PLATFORM (i.e., THE SITE AND/OR MOBILE APPLICATION).</b></p>
                            <p>The website www.moneylens.in <span style={{textDecoration: 'underline'}}> (“Website”)</span> and the mobile application MoneyLens <span style={{textDecoration: 'underline'}}>(“Mobile App”)</span>  are owned, operated, and managed by MoneyLens Private Limited. For the purpose of providing the MoneyLens Platform Services, MoneyLens allows its Group Entities to access and utilize the platform to help you manage your assets and track your profit and loss on investments.</p>
                            {/* <p>THESE TERMS IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.</p> */}
                            <p>Please note that your browsing, accessing, downloading, and/or using MoneyLens Platform are subject to the terms and conditions stated herein; if You do not agree to any or all of the following terms and conditions, You should not use or access the MoneyLens Platform and/or MoneyLens Platform Services in any manner.</p>
                            
                            <h4><b style={{fontSize: '16px'}}>DEFINITIONS</b></h4>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                {/* <li><b>“We”</b>, <b>“Us”</b>, <b>“Our”</b>, <b>“Company”</b>&nbsp;- shall refer to Finzoom Investment Advisors Private Limited and its Group Entities.</li>
                                <li><b>“You”</b>, <b>“Yours”</b>, <b>“Yourself”</b>, <b>“User”</b>, <b>“MoneyLens User”</b>&nbsp;- refers to any individual, non- individual or corporate body.</li>
                                <li><b>“Group Entities”</b>&nbsp;- shall mean affiliates, holding and subsidiaries of Finzoom Investment Advisors Private Limited (“Finzoom”) i.e., Finzoomers Services Private Limited (“Finzoomers”) MoneyLens Private Limited (“INPL”); ADfi Digital Private Limited (“ADfi”) and MoneyLens Global (IFSC) Private Limited. Finzoom is a SEBI registered investment advisor (SEBI Reg. No. INA100012190), Finzoomers is a IRDAI registered corporate agent (non-exclusive) (IRDAI CA Reg. No. CA0744) and PFRDA registered Point of Presence Online) bearing registration no: 362042023; INPL is a SEBI registered Stock-Broker (SEBI Reg. No. INZ000305337) and Depository Participant (SEBI Reg. No. IN-DP-690-2022), MoneyLens Global (IFSC) Private Limited is IFSCA registered broker dealer (IFSC/BD/2023-24/0016) and Depository participant (IFSC/DP/2023-24/010).</li><li><b>“Government Authority”</b> shall mean any national, state, local, provincial, municipal, district or other sub-division governmental authority, statutory authority including Securities and Exchange Board of India (SEBI), Reserve Bank of India (RBI), Insurance Regulatory Development Authority of India (IRDAI), CDSL, government department, administrative authority including (Indian) stock exchange such as NSE, BSE, commission, board, tribunal or court or other law, rule or regulation making entity or competent authority.</li> */}
                                <li><b>“MoneyLens Platform”</b> shall mean and include the Mobile App and Website under the brand name MoneyLens, owned, operated, and managed by MoneyLens Private Limited and made available for use by its Group Entities to help you manage your assets and track your profit and loss on investments.</li>
                                {/* <li><b>“MoneyLens Platform Services”</b> – refers to the services and products available through the MoneyLens Platform, which include, but are not limited to, investment advisory services, mutual fund distribution, connector services for US stocks, facilitation of fixed deposits, acting as a corporate agent for insurance, functioning as a digital lending platform, enabling digital savings accounts, Indian stock brokerage, mini save, and tracking your investments (such as mutual funds) and expenses (such as credit card bills), among other offerings.</li> */}
                                {/* <li><b>“Third Party Service Provider(s)”</b> refers to companies, vendors, business partners, banks, financial institutions, or any other entities whose facilities are utilized by MoneyLens to provide various services, including MoneyLens Platform Services, to users through the MoneyLens Platform.</li> */}
                                <li><b>“Terms” or “Terms and Conditions”</b> – these terms are used interchangeably and shall have the same meaning.</li>
                            </ul>
                            {/* <p>You hereby understand that, the specific products / services availed by You may have their own additional / supplemental terms and conditions as available on MoneyLens Platform and You unequivocally grant Your consent to abide and remain compliant with the said additional / supplemental terms and conditions. When You use any of the MoneyLens Platform Services provided on the MoneyLens Platform, You shall also be subjected to comply with applicable law(s), rule(s), guideline(s), notification(s), circular(s), or such other directive(s) issued by applicable Government Authority.</p> */}
                            <p>You acknowledge and understand that these Terms and Conditions, along with any additional or supplemental terms and conditions related to MoneyLens Platform Services, should be read together. The Company reserves the right to change, amend, or modify these Terms, or any additional or supplemental terms and conditions applicable to the MoneyLens Platform Services, at any time, without prior notice.</p>
                            
                            <h4><b style={{fontSize: '16px'}}>TERMS AND CONDITIONS</b></h4>
                            <p>By registering on MoneyLens Platform and/or accessing the MoneyLens Platform Services, you agree, declare and represent that:</p>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li>You are 18 years of age or older, and if you are acting as a guardian on behalf of a minor, you have the necessary authority to register or sign up for the MoneyLens Platform Services on their behalf. You hereby unconditionally agree to promptly provide and/or execute any additional document(s) as may be requested from time to time, to establish your status as a lawful guardian and to continue accessing the MoneyLens Platform, including MoneyLens Platform Services. If the Company learns that it has inadvertently collected personal information from someone under the age of 18, it will delete that information as quickly as possible. If you believe that a person under 18 may have provided us with personal information, please contact us at <a href="mailto:privacy@moneylens.in">privacy@moneylens.in</a> or <a href="mailto:support@moneylens.in">support@moneylens.in</a>.</li>
                                <li>You are legally capable of entering into a binding contract and confirm that you are not prohibited or restricted by law from using the MoneyLens Platform (including all MoneyLens Platform Services).</li>
                                {/* <li> You hereby grant your explicit consent to fetch Your credit data from Credit Information Companies such as CRIF High Mark and/or Experian and disclose Your credit score to the Company. You permit to Company, to use your credit score to determine your eligibility for access to and/or for offering of various services in tie-up with Third Party Service Providers or other value-added services for improving your credit score.</li> */}
                                {/* <li> You agree that you will be allowed to make any transaction through the MoneyLens Platform when you complete the know your client (“KYC”) process and provide the complete information including personal information in accordance with the KYC guidelines as issued by SEBI and/or IRDAI and/or any other regulatory body, government authorities, competent authority from time to time. For availing various MoneyLens Platform Services, You hereby grant your consent and permit Company to share your KYC information with its Group Entities, and/or with Third Party Service Providers offering your various products and services, to enable them to promptly respond to your queries / interests. The collection, processing, storage, and sharing of Your information is further governed by Company’s Privacy Policy.</li> */}
                                <li>You understand and agree that you will use the MoneyLens Platform (including MoneyLens Platform Services) solely for your personal use and purposes. You will ensure that your use of the MoneyLens Platform and any reasons for accessing MoneyLens Platform Services are strictly for valid and lawful purposes. You remain fully responsible for all transactions made or requested through your MoneyLens Platform account.</li>
                                {/* <li> You agree that transactions made through MoneyLens Platform shall be through your own bank account only and the said transactions do not violate or contravene to any law for the time being in force including Income Tax Act, anti-money laundering laws, anti-corruption laws, prevention of money laundering act etc. and the rules made thereunder.</li> */}
                                <li>You are responsible for maintaining the confidentiality of your MoneyLens Platform account, including your login credentials and OTPs. In the event your device is lost or stolen, you must immediately take all necessary actions to secure your MoneyLens Platform account, such as blocking your SIM card and logging out of your active session. You agree to notify the Company promptly by emailing <a href="mailto:support@moneylens.in">support@moneylens.in</a> and providing all relevant information to support your claim. This will enable the Company to block your MoneyLens Platform account. While the Company will make commercially reasonable efforts to block or suspend your account, you understand that the Company is acting in good faith to help secure your account and assumes no responsibility or liability for any losses, fraud, or unauthorized transactions that may occur under your MoneyLens Platform account.</li>
                                {/* <li>You shall not do or attempt to do any of the following acts: (i) impersonate any person or entity, or falsely stating your age or affiliation with any person or entity; (ii) posts any third-party intellectual property rights or other property rights infringing contents; (iii) act in violation or contravention to any law for the time being in force; reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of MoneyLens Platform or any part thereof; (iv) engage in, post, or transmit any message which is libellous, defamatory, pornographic, vulgar or offensive in nature or otherwise inconsistent with or contrary to the laws in force;You shall not do or attempt to do any of the following acts: (i) impersonate any person or entity, or falsely stating your age or affiliation with any person or entity; (ii) posts any third-party intellectual property rights or other property rights infringing contents; (iii) act in violation or contravention to any law for the time being in force; reverse engineer, decompile, disassemble or otherwise attempt to discover the source code of MoneyLens Platform or any part thereof; (iv) engage in, post, or transmit any message which is libellous, defamatory, pornographic, vulgar or offensive in nature or otherwise inconsistent with or contrary to the laws in force</li> */}
                                <li>The information you provide during registration on the MoneyLens Platform is complete, true, correct, updated, accurate, and belongs to you. If your information is not accessible online and you wish to change, modify, update, or delete your personal or other information, please contact us immediately at <a href="mailto:support@moneylens.in">support@moneylens.in</a> or <a href="mailto:privacy@moneylens.in">privacy@moneylens.in</a>.</li>
                                <li>You are responsible for notifying the Company of any material changes to your personal information and/or profile. The Company will rely on the most recent information provided by you.</li>
                                {/* <li> You agree to be contacted by Company (including its employees, representatives, officers, partners etc.), Third Party Service Providers over phone and/or e-mail and/or SMS/and or push notifications or any other form of electronic communication in connection with your registration, advisory and transactions. This consent overrides any registration for DNC/NDNC. You agree and confirm that if your mobile number is registered in the Do Not Disturb (DND) list of TRAI, you may not receive SMS from MoneyLens. You agree to take steps to deregister from the DND list and shall not hold MoneyLens liable for non-receipt of SMS. You can always opt to stop receiving any or all such communications by writing to <a href="mailto:support@moneylens.in"> support@moneylens.in</a> . You can also delete Your account at any point of time by writing to<a href="mailto:support@moneylens.in"> support@moneylens.in</a> or by visiting the delete account section on the MoneyLens Platform.</li> */}
                                <li>The Company will make its best efforts to provide alerts via SMS, email, WhatsApp, or push notifications. It shall be deemed that you have received the information sent by the Company as an alert to the mobile phone number or email address you provided when registering on the MoneyLens Platform or using any MoneyLens Platform Services. You hereby grant the Company the right and authority to use your contact details, as provided during registration, as your primary, valid, and default communication address for all MoneyLens Platform Services. The Company is not obligated to verify the identity of the person(s) receiving the alert. You cannot hold the Company liable for any unavailability of the SMS/email alert or push notification service in any manner whatsoever.</li>
                                <li>You understand that the SMS/email alert, WhatsApp, or push notification service provided by the Company is an additional convenience and may be subject to errors, omissions, or inaccuracies. If you notice any error in the information provided in the alert, you agree to immediately inform the Company, and we will make the best possible efforts to rectify the error as soon as feasible. You shall not hold us liable for any loss, damage, claim, expense, including legal costs, incurred or suffered by you due to the SMS/email alert or push notification service.</li> <li>You acknowledge that the Company relies on Third Party Service Providers to enable SMS/email alerts, WhatsApp, push notifications, and OTPs. The readability, accuracy, and timely delivery of these services are subject to multiple factors, including the infrastructure and connectivity of the Third Party Service Providers. The Company makes no representation or assurance regarding timely or delayed delivery. The Company shall not be liable for any non-delivery, delayed delivery, or miscommunication of messages or alerts.</li>
                                <li>You are responsible for maintaining the confidentiality of your MoneyLens Platform account information and are fully accountable for all activities that occur under your account. You agree to keep your login credentials safe, secure, and confidential at all times. In case of any actual or suspected unauthorized use of your account, you agree to immediately inform the Company. You will remain responsible for any transactions in your account if you knowingly or negligently (i) grant another person access to your account, (ii) allow someone else to transact on your behalf, or (iii) transact on behalf of another person in any manner. The Company cannot and shall not be held liable for any losses or damages arising from your failure to comply with these provisions.</li>
                                <li>You acknowledge that the software and hardware underlying the application, as well as other internet-related software required to access the application, are the legal property of either the Company or its respective Third Party Service Providers. The permission granted by the Company to access the MoneyLens Platform does not convey or grant any proprietary or ownership rights in the aforementioned software or hardware.</li>
                                <li>You understand and accept that not all products and services offered on or through the MoneyLens Platform are available in all geographic areas and/or to all users. You may not be eligible for all products or services offered by the Company or Third Party Service Providers on the MoneyLens Platform. The Company and Third Party Service Providers reserve the right to determine the availability and eligibility for any product or service offered via the MoneyLens Platform.</li>
                                {/* <li>The Company is not responsible for the availability of content or other services on third party sites linked from MoneyLens Platform. You are aware that access of hyperlinks to other internet sites are at your own risk and the content, accuracy, opinions expressed, and other links provided by these sites are not verified, monitored or endorsed by Company in any way. The Company urges You to read the terms of use, privacy policy and/or other conditions available on respective third party sites, before accessing or registering with any of such third party sites. Company does not make any warranties and expressly disclaims all warranties express or implied, including without limitation, those of merchantability and fitness for a particular purpose, title or non-infringement with respect to any information or services or products that are available or advertised or sold through these third-party platforms.</li><li>You agree that You will not use the MoneyLens Platform for any purpose that is unlawful or prohibited by these Terms. You also agree You will not use the MoneyLens Platform in any manner that could damage, disable or impair the application or interfere with any other party’s use, legal rights, or enjoyment of the application. You hereby represent and warrant that you shall make use of the MoneyLens Platform as a prudent, reasonable and law-abiding citizen and you shall comply with relevant applicable laws.</li> */}
                                <li>The Company reserves the right, at its sole discretion, to delete, block, restrict, disable, or suspend your account or any part thereof. If a User is found engaging in fraudulent or illegal activities, including but not limited to abusing any representatives of the organization, participating in fraudulent activities on the MoneyLens Platform, using mass media and/or bots to engage with the platform, or using mass media and/or bots to harm the organization’s reputation, such activities may be referred to the appropriate legal authority for legal recourse. Any violation of these terms and conditions may result in action being taken against the User.</li>
                                {/* <li>The Company has engaged Third Party Service Providers for payment gateway services, account aggregator services etc. The engagement of these Third-Party Service Providers is to facilitate users to conduct transactions seamlessly and securely on MoneyLens Platform. However, Company assumes no responsibility or liability in any manner whatsoever, for any delayed or non-processing of transactions. You understand that Company and/or partnered financial institutes shall be well within its rights to not to act and/or suspend the services if the transaction is not successful or delayed credit of transactions takes place. You understand and accept the risk associated with processing of online transactions and agrees that Company shall not in any manner whatsoever be responsible to You for any losses, cost, interest, penalties, levies, charges or such amount that may be recoverable from you due to delay in or non-processing of transaction is levied. It is advised that, You should diligently keep track of all your payments are done in timely and successful manner. </li> */}
                                <li>The MoneyLens Platform offers services to its users, including but not limited to transactions in securities or financial products, and/or providing advice on investment portfolios containing securities or investment products.</li>
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>SERVICES:</b></h4>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                {/* <li>Please also note that there are other financial products and services, such as US Stocks, loans, fixed deposit creation, insurance, savings bank account, peer to peer lending etc. manufactured and/or distributed by third party service providers, offered by or through the MoneyLens Platform. However, it is hereby expressly clarified that any or all interaction, communication, dealing, or transaction between you and such Third Party Service Provider(s) in respect of availing of any products/services offered by said Third Party Service Provider(s) forms a separate and independent transaction between the you and such Third Party Service Provider(s) without any liability accruing to or on Company for any matters arising out of or in relation to Third Party Service Provider(s) (including their products / services) act, conduct, omission, negligence, default, breach of confidentiality, breach of applicable laws, discharge of obligations etc.. </li> */}
                                <li>You hereby agree and acknowledge that all decisions regarding the buying, selling, holding, or otherwise dealing with investment securities, regardless of the services provided by the Company, will be based on your independent evaluation of the risks and rewards of the investments, as well as your own verification of all relevant facts, including financial and other circumstances, and a proper evaluation of those factors.</li>
                                <li>Neither the Company nor any of its directors, employees, or agents shall be liable for any advice or representation made by them herein. It will be your responsibility to make an independent assessment when using the MoneyLens Platform/Services or availing any product or service from Third-Party Service Providers.</li>
                                <li>You acknowledge and agree that the Company does not guarantee that availing services from the MoneyLens Platform will result in profits, avoid losses, or meet your investment objectives. Additionally, the Company does not guarantee that the services or the MoneyLens Platform will be unaffected by adverse tax consequences, technical failures, or delays in regulatory compliance with new laws. The Company shall not be liable for any error in judgment or loss suffered by you in connection with the services provided.</li>
                                {/* <li>Company does not disburse loans on its own, but merely enables You to compare the best possible options and apply for loans to various banks and NBFCs (Non-Banking Financial Corporation) in the Indian market. You understand and acknowledge that the loan rates may vary from bank to bank and is dependent on your credit profile and the loan/policies/scheme you decide to opt for. Company has nothing to do with the rates offered by Banks/NBFCs.</li> */}
                                <li>You agree and understand that by availing the services, you shall be bound by our Privacy Policy. </li>
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>CONFIDENTIALITY</b></h4>
                            <p>You acknowledge that, during your relationship with the Company and while using the services, you may come across information related to the services and/or the Company (“Proprietary Information”). Such Proprietary Information shall remain the exclusive property of the Company and includes, but is not limited to, the features and operation of the services, trade secrets, know-how, inventions (whether patentable or not), techniques, processes, programs, ideas, algorithms, schematics, testing procedures, software design and architecture, computer code, internal documentation, design and function specifications, product requirements, problem reports, analysis and performance data, benchmarks, software documentation, and other technical, business, product, plans, and data. Regarding this Proprietary Information:</p>
                            <ul style={{listStyleType: 'lower-alpha'}}> 
                                <li>You shall not use or disclose Proprietary Information (except as expressly authorized by this Agreement) without the prior written consent of the Company, unless such Proprietary Information becomes publicly available through no fault of yours and without breach of this Agreement.</li> 
                                <li>You agree to take reasonable measures to maintain the confidentiality of the Proprietary Information and Services.</li> 
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>USE AND PROTECTION OF INTELLECTUAL PROPERTY RIGHTS</b></h4>
                            <p>The MoneyLens Platform is protected by copyright, trademarks, patents, trade secrets, and/or other relevant intellectual property laws. No information, content, or material from the MoneyLens Platform may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any manner without the express written permission of the Company. You are granted a limited license to use the MoneyLens Platform for personal and non-commercial use, subject to your agreement with these Terms. You agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of the MoneyLens Platform.</p>

                            <h4><b style={{fontSize: '16px'}}>PRIVACY</b></h4>
                            <p>You agree that during your use and access of the MoneyLens Platform and/or availing the services offered by the MoneyLens Platform, you will provide us with certain information and other data, which may or may not be publicly available. Please note that we respect the privacy and confidentiality of such data. The provisions pertaining to such private information and data provided by you are governed by the MoneyLens Platform's Privacy Policy, which is available at <a href="https://www.moneylens.in/privacy-policy">https://www.moneylens.in/privacy-policy</a>. By using and visiting the MoneyLens Platform and availing the MoneyLens Platform Services.</p>

                            <h4><b style={{fontSize: '16px'}}>LIMITATION OF LIABILITY, INDEMNITY, AND WARRANTY</b></h4>
                            <ul style={{listStyleType: 'lower-alpha'}}>
                                <li>In no event shall the Company, its directors, employees, associates, partners, or suppliers be liable to you for any loss or damage arising from or in relation to these terms and conditions, your use of the MoneyLens Platform, investments made through the MoneyLens Platform, or the availing of any products or services from any third-party service provider.</li>
                                <li>You agree to indemnify and hold harmless the Company, its directors, employees, associates, partners, and suppliers from any liabilities, including but not limited to claims, damages, lawsuits, or legal expenses incurred due to: (i) your use or misuse of the MoneyLens Platform, (ii) your failure to perform or comply with the terms and conditions, or (iii) any actions, negligence, misconduct, or fraudulent behavior on your part.</li>
                                <li>You affirm that all information and details you provide to the Company, its directors, employees, associates, partners, or suppliers while using the MoneyLens Platform (including for investment-related activities) will be accurate, complete, current, and truthful.</li>
                                <li>You are solely responsible for any investment decisions you make using the Services, and the Company shall not be liable for any loss or damage resulting from those decisions or any reliance placed on them. You explicitly agree that your use of the MoneyLens Platform is at your own risk.</li>
                                <li>To the maximum extent allowed by applicable law, the Company and its directors, employees, associates, third-party partners, or suppliers disclaim all warranties or guarantees, whether statutory, express, or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights. Any advice or information, whether oral or written, obtained by you from the Company or through the MoneyLens Platform, will not create any warranties or guarantees other than those expressly outlined in these Terms.</li>
                            </ul>

                            <h4><b style={{fontSize: '16px'}}>CONSIDERATION</b></h4>
                            <p>The usage of the MoneyLens Platform is entirely free, and there are no charges or fees associated with using the platform. The Company does not charge any fees for accessing or using the MoneyLens Platform or its services. If any fees or charges are introduced in the future, they will be clearly communicated to you in advance. All services on the MoneyLens Platform are currently offered without any cost to the users.</p>

                            {/* <h4><b style={{fontSize: '16px'}}>SHIPPING POLICY</b></h4>
                            <p>The users are hereby notified that all or any of the information (such as: Acknowledgement of Mutual fund booked; soft copy of insurance policy document; copy of provisional fixed deposit receipts, invoices pertaining to services such as membership services, advisory services, withdrawal charges, stock broking trade receipts, contract notes, margins statements (at pre-defined frequency) etc.) pertaining to the products purchased via MoneyLens Platform will be delivered to them on their registered email id. If required or mandated under applicable law, Company may be required to dispatch certain documents physically to Your registered address. You agree to always keep Your personal information up to date to avoid any chances of non-delivery of information and that Company shall be entitled to act upon most recent details furnished by You. </p> */}

                            <h4><b style={{fontSize: '16px'}}>DISCLAIMERS</b></h4>
                            <p>
                                THE USER ACKNOWLEDGES AND UNDERSTANDS THAT THE MONEYLENS PLATFORM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE MAKE NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, REGARDING THE OPERATION OF THE MONEYLENS PLATFORM OR THE INFORMATION AND CONTENT PRESENTED ON IT. YOU EXPRESSLY AGREE THAT YOUR USE OF THE MONEYLENS PLATFORM IS AT YOUR OWN RISK.
                                <br/>
                                TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED. WE DO NOT GUARANTEE THAT THE MONEYLENS PLATFORM, ITS SERVERS, OR ANY EMAILS/COMMUNICATIONS SENT FROM THE MONEYLENS PLATFORM ARE FREE FROM VIRUSES OR OTHER HARMFUL COMPONENTS. WE SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE MONEYLENS PLATFORM, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES.
                                <br/>
                                INVESTMENTS ARE SUBJECT TO MARKET RISKS. PLEASE REVIEW ALL SCHEME DETAILS CAREFULLY. PAST PERFORMANCE DOES NOT GUARANTEE FUTURE RETURNS.
                                <br/>
                                ANY INTERACTIONS, COMMUNICATIONS, DEALINGS, OR TRANSACTIONS BETWEEN USERS AND THIRD-PARTY PROVIDERS REGARDING PRODUCTS OR SERVICES OFFERED BY SUCH PROVIDERS ARE SOLELY BETWEEN THE USER AND THE THIRD-PARTY PROVIDER. WE SHALL NOT BE LIABLE FOR ANY ISSUES ARISING FROM THESE INTERACTIONS. USERS AGREE TO INDEMNIFY AND HOLD US HARMLESS FROM ANY CLAIMS, DAMAGES, LOSSES, OR EXPENSES RESULTING FROM SUCH ENGAGEMENTS. WE HAVE NO CONTROL OVER THESE TRANSACTIONS AND DO NOT GUARANTEE, ENDORSE, OR ACCEPT RESPONSIBILITY FOR ANY THIRD-PARTY PRODUCTS OR SERVICES. USERS SHOULD EXERCISE CAUTION AND MAKE INFORMED DECISIONS WHEN ENGAGING WITH THIRD-PARTY PROVIDERS.
                                {/* <br/>
                                THE CONNECTOR SERVICE FOR US STOCKS ACCOUNT AND THE TRANSACTION FACILITATION SERVICES FOR INSURANCE PRODUCT, FIXED DEPOSIT PRODUCTS ARE PROVIDED BY FINZOOMERS SERVICES PRIVATE LIMITED. PLEASE BE INFORMED THAT US STOCKS, INSURANCE, FIXED DEPOSITS ARE NOT EXCHANGE TRADED FUNDS AND FINZOOMERS SERVICES PRIVATE LIMITED ACTS IN THE CAPACITY OF FACILITATING THESE PRODUCTS. ALL DISPUTES WITH RESPECT TO THE TRANSACTION FACILITATION SERVICES, WOULD NOT HAVE ACCESS TO INDIAN EXCHANGE INVESTOR REDRESSAL FORUM OR ARBITRATION MECHANISM.  */}
                                {/* <br/>
                                THE LOGOS/ TRADEMARKS ADVERTISED IN THE PLATFORM ARE THE PROPERTY OF RESPECTIVE TRADEMARK OWNERS AND BY DISPLAYING IT HAS NO RIGHT, TITLE, INTEREST OVER IT. THE USER EXPRESSLY AGREES THAT FINZOOM IS NOT LIABLE OR RESPONSIBLE AND DOES NOT REPRESENT OR WARRANT FOR ANY DAMAGES / ANY LOSS OF INTEREST AND/ OR OPPORTUNITY LOSS AND /OR ANY LOSS ARISING DUE TO MOVEMENT OF NET ASSET VALUE (NAV), NOTIONAL OR OTHERWISE DUE TO NON-EXECUTION OF ORDERS OR ANY INCORRECT EXECUTION OF ORDERS WITH REGARD TO THE MUTUAL FUNDS CHOSEN BY THE USER DUE TO, BUT NOT BEING LIMITED TO, ANY LINK/SYSTEM FAILURE, SUFFICIENT/INSUFFICIENT FUNDS IN THE USER’S BANK ACCOUNT WHICH MAY OCCUR AT USER’S END. MUTUAL FUND INVESTMENTS ARE SUBJECT TO MARKET RISKS, READ ALL SCHEME RELATED DOCUMENTS CAREFULLY. THIS IS IN NO WAY TO BE CONSIDERED AS AN ADVICE OR RECOMMENDATION. MUTUAL FUNDS TRANSACTION FACILITATION SERVICES ARE PROVIDED BY FINZOOM INVESTMENT ADVISORS PRIVATE LIMITED IN PARTNERSHIP WITH BSE STAR MF PLATFORM. PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE RETURNS. PERFORMANCE AND RETURNS OF ANY INVESTMENT PORTFOLIO CAN NEITHER BE PREDICTED NOR GUARANTEED.
                                <br/>
                                MUTUAL FUND INVESTMENTS ARE NOT EXCHANGE TRADED SERVICES. FINZOOM INVESTMENT ADVISORS PRIVATE LIMITED ACTS IN THE CAPACITY OF FACILITATOR OF THIS PRODUCT. FINZOOM IS ONLY PROVIDING TECHNOLOGY SERVICES AND ALL DISPUTES WITH RESPECT TO MUTUAL FUND INVESTMENTS WOULD NOT HAVE ACCESS TO INVESTOR REDRESSAL OR ARBITRATION MECHANISM IN INDIA. FINZOOM ACCEPT NO LIABILITIES FOR ANY LOSS OR DAMAGE OF ANY KIND ARISING OUT OF ANY ACTION BY THE USER.  */}
                            </p>

                            {/* <h4><b style={{fontSize: '16px'}}>FORCE MAJEURE </b></h4>
                            <p>If the whole or any part of the performance is prevented, hindered or delayed by a Force Majeure event (as defined below), Company shall not be liable for any failure to perform any of its obligations under these terms and conditions or those applicable specifically to its services/facilities, and in such case its obligations shall be suspended for so long as the Force Majeure event continues. “Force Majeure Event” means any event, due to any cause beyond the reasonable control of Company, including without limitations, unavailability of any communication systems, breach, or virus in the digital processes or payment or delivery mechanism, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, lockdown, computer hacking, unauthorised access to computer data and storage devices, computer crashes, malfunctioning in the computer terminal or the systems getting affected by any malicious, destructive or corrupting code or program, mechanical or technical errors/failures or power shut down, faults or failures in telecommunication etc. </p> */}

                            {/* <h4><b style={{fontSize: '16px'}}>SEVERABILITY </b></h4>
                            <p>All illegality, invalidity or unenforceability of any provision of these Terms under the law of any jurisdiction will not affect its legality, validity or enforceability under the law of any other jurisdiction nor the legality, validity or enforceability of any other provision. </p> */}

                            <h4><b style={{fontSize: '16px'}}>WAIVER</b></h4>
                            <p>No failure or delay by any party in exercising any right or remedy under this Agreement shall be deemed a waiver of that right or remedy, nor shall any partial or single exercise of any right or remedy prevent further exercise of that right or any other right or remedy.</p>

                            <h4><b style={{fontSize: '16px'}}>ASSIGNMENT</b></h4>
                            <p>You agree that we may transfer, subcontract, or otherwise assign our rights and/or obligations under these terms at any time without further notice. You acknowledge that you cannot assign, transfer, or subcontract the terms or any rights granted hereunder to any third party.</p>

                            {/* <h4><b style={{fontSize: '16px'}}>DISPUTE RESOLUTION</b></h4>
                            <p>Any dispute, controversy, claims or disagreement of any kind whatsoever between the parties in connection with or arising out of this Agreement shall be referred for arbitration, to a sole arbitrator appointed by Company, through arbitration to be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat and venue of such arbitration shall be at New Delhi, India. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties</p>

                            <h4><b style={{fontSize: '16px'}}>GOVERNING LAWS </b></h4>
                            <p>These Terms shall be governed, interpreted, and construed in accordance with the laws of India, without regard to the conflict of law provisions and for resolution of any dispute arising out of your use of the Services or in relation to these Terms. Notwithstanding the foregoing, you agree that (i) Company has the right to bring any proceedings before any court/forum of competent jurisdiction and you irrevocably submit to the jurisdiction of such courts or forum; and (ii) any proceeding brought by you shall be exclusively before the courts in New Delhi, India. </p> */}

                            <h4><b style={{fontSize: '16px'}}>ENTIRE AGREEMENT</b></h4>
                            <p>These Terms, along with any other applicable laws, guidelines, rules, additional or supplemental terms & conditions, and policies of the MoneyLens Platform, including the Privacy Policy and any other agreements related to the Services (such as the Advisory Agreement for advisory clients), form the entire agreement between the User and Us. This agreement supersedes all prior agreements, promises, proposals, representations, understandings, and negotiations, whether written or oral, between the User and Us regarding the subject matter herein.</p>

                            {/* <h4><b style={{fontSize: '16px'}}>SURVIVAL</b></h4>
                            <p>Notwithstanding the termination or rescission of this Agreement, the provisions of this Agreement shall continue to apply to those duties and obligations which are intended to survive any such cancellation, termination or rescission, including, without limitation clauses related to Limitation of liability, Indemnity, Warranty, Intellectual Property, Confidentiality, Dispute Resolution, Governing Law and Jurisdiction. Further any provisions of this Agreement which by implication are to survive the termination of this Agreement shall survive such termination. Termination of the Agreement shall not abate the causes of action that have accrued to the parties prior to such termination. </p> */}

                            <h4><b style={{fontSize: '16px'}}>USER SUPPORT </b></h4>
                            <p>The Company will assist and coordinate with Users regarding any issues or queries raised and will resolve them on a best-effort basis. Users can submit their queries by emailing us at <a href="mailto:support@moneylens.in">support@moneylens.in</a> or by contacting us through any updated email address displayed on the MoneyLens Platform.</p>

                            <h4><b style={{fontSize: '16px'}}>MODIFICATION OF TERMS</b></h4>
                            <p>The Company reserves the right to change, amend, or modify, from time to time, any provision related to the MoneyLens Platform (including MoneyLens Platform Service(s)) or these Terms, which also includes changing the extent and scope of the services and/or adding any other category, service, facility, or feature within the term 'MoneyLens Platform Service', at the sole discretion of the Company. Any such changes shall be effective immediately upon the posting of revised Terms and may be notified via the MoneyLens Platform. By using the services provided through this MoneyLens Platform, you shall be deemed to have accepted the Terms herein, including any amended Terms published on the MoneyLens Platform. Your continued use of the MoneyLens Platform following the posting of changes means that you accept and agree to the changes. If you do not agree with any such change, your sole and exclusive remedy is to terminate your use of the MoneyLens Platform. The User’s use and access of the MoneyLens Platform/Service(s) is subject to the most recent version of these Terms made available on the MoneyLens Platform at the time of such use.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsAndCondition;