import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import '../css/feedback.css';

const FeedBack = () => {
    const [formData, setFormData] = useState({
        question1: '',
        question2: '',
        question3: '',
        question4: '',
    });
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [agree, setAgree] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsButtonLoading(true);

        // Simulate saving data (replace with actual API call)
        setTimeout(() => {
            console.log('Form submitted:', formData);
            setIsButtonLoading(false);
        }, 2000);
    };

    return (
        <div className="container">
            <div className='feedback-container card'>
                <div className='card-body p-3'>
                    <h3>Your Opinion Shapes MoneyLens! </h3>
                    <h3 className="feedback-heading">MoneyLens is built to simplify wealth insights and give you personalised recommendations to grow your net wealth. By sharing your experiences, you help us prioritize improvements and build the best tools for managing your financial wealth. </h3>
                    <Form noValidate onSubmit={handleSubmit} className="us-stock">
                        <Form.Group controlId="question1">
                            <Form.Label>1. What are your favourite features of MoneyLens so far?</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Add your comments here"
                                value={formData.question1}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="question2">
                            <Form.Label>2. What functionality are you missing or would like to see? </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Add your comments here"
                                value={formData.question2}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="question3">
                            <Form.Label>3. Did you encounter any issues or bugs that you'd like to report?  </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Add your comments here"
                                value={formData.question3}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="question4">
                            <Form.Label>Additional Comments or Suggestions: </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Additional Comments or Suggestions"
                                value={formData.question4}
                                autoComplete="off"
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="agree" className="form-check-group">
                            <Form.Check
                                type="checkbox"
                                label="I agree to my feedback being used to improve MoneyLens."
                                checked={agree}
                                onChange={() => setAgree(!agree)}
                                className="custom-checkbox"
                            />
                        </Form.Group>
                        <Form.Group controlId="feedBackSubmit" className="btn-container">
                            <Button variant="primary" type="submit" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Submiting...' : 'Submit'}
                            </Button>
                        </Form.Group>
                        
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default FeedBack;
