import React, { useState, useRef, useEffect } from 'react';
import './css/Login.css';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getFullUrl, apiRequest, setCookie, getCookie } from './utils/helpers';
import { apiConfig } from './ApiConfig';
import { trackPageView, trackEvent } from './utils/googleAnalytics';

const OTPForm = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const { login, isAuthenticated } = useAuth();

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(0);
    const [emailVerify, setEmailVerify] = useState('');

    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = useRef([]);

    useEffect(() => {
        // Focus on the first input when the component mounts
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);
    useEffect(() => {
        const email = getCookie('emailVerify')
        if (email) {
            setEmailVerify(email);
            setCookie('emailVerify', email, 7);
        }
    }, [emailVerify]);

    useEffect(() => {
        if (isAuthenticated) {
          // If user is authenticated, redirect to /networth
          navigate('/networth', { replace: true });
        }
    }, [isAuthenticated, navigate]);

    const handleInputChange = (event, index) => {
        const value = event.target.value.substring(0, 1); // Limit to one character
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < 5 && /^[0-9]$/.test(value)) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handlePaste = (event) => {
        const pasteData = event.clipboardData.getData('Text').slice(0, 6).split('');
        const newOtp = [...otp];
    
        pasteData.forEach((char, index) => {
            if (/^[0-9]$/.test(char) && index < 6) {
                newOtp[index] = char;
            }
        });
    
        setOtp(newOtp);
    
        // Check for the next empty field or focus on the last field if all are filled
        const nextEmptyIndex = newOtp.findIndex((val) => val === '');
        if (nextEmptyIndex !== -1 && inputRefs.current[nextEmptyIndex]) {
            inputRefs.current[nextEmptyIndex].focus();
        } else if (inputRefs.current[5]) {
            // Focus on the last field if all are filled
            inputRefs.current[5].focus();
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1].focus();
        }
    };

    const resendOTP = async (e) => {
        e.preventDefault();
        
        if(!emailVerify) {
            setError("Please enter valid email.");
            setIsButtonLoading(false);
            return;
        }

        const formData = {
            email: emailVerify
        }
        try {
            // Track the resend OTP event
            trackEvent('OTP', 'Resend OTP', `Resent OTP to ${emailVerify}`);
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.SENDOTP, 'POST', formData);
            
            if (result.statusCode === 200) {
                setError(null);
                setSuccess(true)
                setTimeout(() => setSuccess(false), 3000);

                // Start the timer for 30 seconds
                setIsButtonDisabled(true);
                setTimer(30);
                trackEvent('OTP', 'Resend OTP', `Success`);
            } else if (result.statusCode === 401) {
                setError(result.message);
                trackEvent('OTP', 'Resend OTP', `Failed:${result.statusCode} ${result.message}`);
            }
        } catch (error) {
            setError('Oops! Something went wrong. Please try again later.');
            trackEvent('OTP', 'Resend OTP', `Failed: ${error.message}`);
        } 
    };

    // Timer countdown logic
    useEffect(() => {
        if (timer > 0) {
        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
        } else if (timer === 0) {
        setIsButtonDisabled(false); // Re-enable the button after countdown ends
        }
    }, [timer]);

    const verifyOTP = async (e) => {
        e.preventDefault();
        setError(null);
        setIsButtonLoading(true);
        setError(null);

        // Check if all OTP fields are filled
        if (otp.some(field => field === '')) {
            setError("All OTP fields must be filled.");
            setIsButtonLoading(false);
            return;
        }

        if(!emailVerify) {
            setError("Please enter valid email.");
            setIsButtonLoading(false);
            return;
        }

        const formData = {
            email: emailVerify,
            otp: parseInt(otp.join(''))
        }
        try {
            // Track the OTP verification event
            trackEvent('OTP', 'Verify OTP', `OTP Verification attempt for ${emailVerify}`);
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.VERIFYOTP, 'POST', formData);

            if (result.statusCode === 200) {
                trackEvent('OTP', 'Resend OTP', 'OTP Verification Success');
                login(result.data);
            } else if (result.statusCode === 401) {
                setError(result.message);
                trackEvent('OTP', 'Resend OTP', `Failed:${result.statusCode} ${result.message}`);
            }
        } catch (error) {
            setError('Oops! Something went wrong. Please try again later.');
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <div className='login-main-margin'>
            <div className='signup-main-container'>
                <span className='reset-back' onClick={() => navigate('/signup')}><img alt="leftArrow" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" src={getFullUrl('images/back-black-icon.png')} /> Back</span>
                <div className="sign-panels">
                    <div className="signup">
                        <div className='logo-container'>
                            <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "3rem", height: "3rem" }} />
                            <div className='logo-name'>MoneyLens</div>
                        </div>
                        <div className="title">
                            <span>OTP Verification</span>
                            <p className="info" style={{marginTop: '1rem'}}>An otp has been sent to {emailVerify}</p>
                            <p className="msg info" style={{fontSize: 'unset'}}>Please enter OTP to verify</p>
                        </div>
                        <Form noValidate onSubmit={verifyOTP}>
                            <Form.Group controlId="otp" className="otp-input-fields">
                                {otp.map((_, index) => (
                                    <Form.Control
                                        key={index}
                                        type="number"
                                        max={9}              // Limits to numbers between 0 and 9
                                        maxLength={1}        // Limits input to a single character
                                        placeholder='*'
                                        className={`otp__digit otp__field__${index + 1} ${error ? 'otp-error' : ''}`}
                                        value={otp[index]}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={index === 0 ? handlePaste : undefined}  // Attach onPaste to the first input only
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />
                                ))}
                            </Form.Group>
                            {success && <div className="success-message">OTP has been resent successfully! Please check your mail.</div>}
                            {error && <div className="error-message">{error}</div>}
                            <span
                                onClick={resendOTP}
                                className={`btn-login ${isButtonDisabled ? 'btn-disabled' : 'btn-fade'}`}
                                style={{ pointerEvents: isButtonDisabled ? 'none' : 'auto' }}  // Disable pointer events
                            >
                                {isButtonDisabled ? `Resend OTP (${timer}s)` : 'Resend OTP'}
                            </span>
                            <Button type="submit" className="btn-signin btn-primary" disabled={isButtonLoading}>
                                {isButtonLoading ? 'Verifying...' : 'Verify'}
                            </Button>

                            <span onClick={() => handleNavigation('/login')} className="btn-login btn-fade">
                                Already have an account? Sign In<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </Form>
                    </div>
                </div>
                <div className='right-container'>
                    <div className='right-top'>
                        <div>Namaste, India!</div>
                        <div className='quotes'>Your financial journey, Simplified!<br />
                        Track. Grow. Thrive.</div>
                    </div>
                    <img src={getFullUrl('images/banner.png')} alt="banner" />
                </div>
            </div>
            <div className="terms-container">
                By proceeding, you agree to the
                <span>&nbsp;<a href="#" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></span>
                &nbsp;and <span><a href="https://moneylens.in/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;</span>
                of MoneyLens
            </div>
        </div>
    );
};

export default OTPForm;
