
import { message } from "antd";

export const isNegative = (num) => {
    return num < 0 || Object.is(num, -0);
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });
};

export const formatDateWithTime = (milliseconds) => {
  const date = new Date(milliseconds);
  return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
  }) + ', ' + date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
  });
};

/**
 * Formats a number value as a currency string with commas.
 * 
 * @param {number|string} value - The value to format. Can be a number or a string.
 * @returns {string} - The formatted value as a string, or an empty string if the value is invalid.
 */
export const formatRupees = (value) => {
  if (isEmptyField(value)) {
      return ''; // Return an empty string for invalid values
  }

  // Convert value to a number if it's a string and ensure it's a valid number
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the value is a valid number
  if (isNaN(numberValue)) {
      return ''; // Return an empty string if the value is not a valid number
  }

  // Format the number with commas
  return numberValue.toLocaleString('en-IN');
};


/**
 * Formats a number into a string representation with suffixes 
 * like 'K' for thousand, 'L' for lakh, and 'Cr' for crore.
 *
 * @param {number} number - The number to format.
 * @returns {string} - The formatted number as a string with the appropriate suffix.
 */
export const shortFormatRupees = (number) => {
  const formatNumber = (num) => {
    const formatted = num.toFixed(2); // Always keep two decimals
    const [whole, decimal] = formatted.split('.');

    // Ensure maximum 2 digits before decimal
    const shortenedWhole = whole.length > 2 ? whole.slice(0, 2) : whole;

    // Return formatted number based on whether decimals are required
    return decimal === '00' ? shortenedWhole : `${shortenedWhole}.${decimal}`;
  };

  // Store whether the number is negative
  const isNegative = number < 0;
  
  // Work with the absolute value of the number for formatting
  const absoluteNumber = Math.abs(number);

  // Check if the number is greater than or equal to 1 Crore (10 million)
  if (absoluteNumber >= 10000000) {
    return (isNegative ? '-' : '') + formatNumber(absoluteNumber / 10000000) + ' Cr';
  } 
  // Check if the number is greater than or equal to 1 Lakh (100 thousand)
  else if (absoluteNumber >= 100000) {
    return (isNegative ? '-' : '') + formatNumber(absoluteNumber / 100000) + ' L';
  } 
  // Check if the number is greater than or equal to 1 Thousand (1000)
  else if (absoluteNumber >= 1000) {
    return (isNegative ? '-' : '') + formatNumber(absoluteNumber / 1000) + 'k';
  } 
  // Handle numbers in the hundreds (100 - 999)
  // else if (absoluteNumber >= 100) {
  //   return (isNegative ? '-' : '') + formatNumber(absoluteNumber / 1000) + 'k'; // Example: 100 -> 0.10k
  // } 
  // For numbers below 100
  else {
    return (isNegative ? '-' : '') + formatRupees(formatNumberWithTwoDecimals(absoluteNumber)); // Assuming formatRupees handles small numbers
  }
};

export const formatNumberWithTwoDecimals = (value) => {
  // Convert the value to a number
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  // Check if the value is a valid number
  if (isNaN(numberValue)) {
    return ''; // Return an empty string if the value is not a valid number
  }

  // Check if the number has decimals
  const hasDecimals = numberValue % 1 !== 0;

  // Format with commas and decimals if needed
  return numberValue.toLocaleString('en-IN', {
    minimumFractionDigits: hasDecimals ? 2 : 0,
    maximumFractionDigits: 2,
  });
};

/**
 * Checks if a value is null, undefined, or blank.
 * 
 * @param {any} value - The value to check.
 * @returns {boolean} - Returns true if the value is null, undefined, or blank; otherwise, false.
 */
export const isEmptyField = (value) => {
  if (value === null || value === undefined) {
      return true; // Value is null or undefined
  }

  if (typeof value === 'string') {
      return value.trim() === ''; // Check if string is blank
  }

  if (Array.isArray(value)) {
      return value.length === 0; // Check if array is empty
  }

  if (typeof value === 'object' && value !== null) {
      return Object.keys(value).length === 0; // Check if object is empty
  }

  return false; // For numbers and other types, the value is not considered blank
};

export const apiRequest = async (url, method = 'GET', data = null) => {
  try {
    // Set up headers
    const headers = {};

    // This array is used to ignore the token for login, signup, send OTP, verify OTP, and reset password requests.
    const authPaths = ['/login', '/register', '/verify', '/reset', '/send'];
    const isAuthRequest = authPaths.some(path => url.includes(path));

    // Only add the token if it's not a login or signup request
    if (!isAuthRequest) {
      const token = localStorage.getItem('token');

      // Check if token is available for non-auth requests
      if (!token) {
        logout();
        throw new Error('Authorization token is missing');
      }

      // Include the token in the Authorization header
      headers['Authorization'] = `Bearer ${token}`;
    }

    // If the data is a JavaScript object, assume JSON
    if (data && !(data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
      data = JSON.stringify(data); // Convert the data object to a JSON string
    }

    // Set up the request options
    const options = {
      method,
      headers,
      body: data, // Body will be null for GET requests
    };

    // Make the API call
    const response = await fetch(url, options);

    // Handle 403 Unathorized token
    if(response.status === 403) {
      logout();
    }

    // Handle 401 Unauthorized error separately
    if (response.status === 401) {
      const errorData = await response.json();
      // Show the response message from the API
      throw new Error(errorData.error.message || 'Unauthorized access');
    }

    // Handle 400 Bad Request
    if (response.status === 400) {
      const errorData = await response.json();
      throw new Error(errorData.error.message || 'Bad Request'); // Return the response data for a 400 status
    }

    // Handle 500 Internal Server Error
    if (response.status === 500) {
      const errorData = await response.json();
      throw new Error(errorData.error.message || 'Internal Server Error'); // Return the response data for a 400 status
    }

    // Handle other HTTP errors
    if (!response.ok) {
      const errorText = await response.text(); // Capture the error message from the server
      throw new Error(`Error ${response.status}: ${errorText}`);
    }

    // Parse the JSON response
    const result = await response.json();
    return (method === 'POST' || method === 'PUT' || method === 'DELETE') ? result : (result.hasOwnProperty('data') ? result.data : result);

  } catch (error) {
    console.error('API request failed:', error.message);
    throw error; // Re-throw the error for further handling
  }
};

const logout = () => {
  const rememberMeData = localStorage.getItem('rememberMeData');
  localStorage.clear();
  
  if (rememberMeData) {
      localStorage.setItem('rememberMeData', rememberMeData);
  }

  window.location.href = '/login';
}

// Function to call the API and cache the data
const callApiAndCacheData = async (url, method, key, refresh, individualInfo) => {
  try {
    let result = await apiRequest(url, method, null);
    // Store fetched data in cache with a dynamic key
    if(key && result !== null) {
      localStorage.setItem(key, JSON.stringify(result));
      setCookie(key, false);
    } else if(!individualInfo) {
      const cachedData = localStorage.getItem(key);
      result = JSON.parse(cachedData);
    } 
    return result;
  } catch (error) {
    if(refresh || individualInfo) showErrorMessage();
    const cachedData = localStorage.getItem(key);
    return JSON.parse(cachedData);
  }
};

// Function to fetch data, either from cache or by calling the API
export const fetchData = async (url, method, key = false, refresh = false, individualInfo = false) => {
  try {
    // Check if individualInfo is true
    if (individualInfo) {
      // Directly call the API and return the result
      return await callApiAndCacheData(url, method, key, refresh, individualInfo);
    }

    // Check if data is available in cache with a dynamic key
    const cachedData = localStorage.getItem(key);
    const isDataDirty = getCookie(key)?.toLowerCase() === "true";
    if (!isDataDirty && cachedData && !refresh) {
      return JSON.parse(cachedData);
    } else {
      // If no cached data, call the API and return the result
      return await callApiAndCacheData(url, method, key, refresh);
    }
  } catch (error) {
      console.error(`Error in api: ${url}`);
    throw error; // Rethrow error for handling in the calling function
  }
};

export const updateDirtyState = (key, value = true) => {
  setCookie(key, value);
}

export const formatDateToInputValue = (timestamp) => {
  const date = new Date(parseInt(timestamp));
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2);
  const day = (`0${date.getDate()}`).slice(-2);
  return `${year}-${month}-${day}`;
};

export const ConvertDateToMilliseconds = (date) => {
  // Convert to Date object
  const dateObj = new Date(date);
  
  // Get milliseconds since epoch
  return dateObj.getTime();
};

export const threeDigitAfterDecimal = (number) => {
  if(!isEmptyField(number)){
    return parseFloat(number).toFixed(2);
  }
}

export const getSortValue = (item, key) => {
  const value = item[key];
  if (Array.isArray(value)) {
    // Handle array values (e.g., 'returns')
    return parseFloat(value[1]); // Assume the first element is a number
  } else if (typeof value === 'string') {
    // Check if the string can be converted to a number
    const numberValue = parseFloat(value);
    
    // If value is a valid number string (like '111'), return it as a number
    if (!isNaN(numberValue)) {
      return numberValue; // Return as a number for sorting
    }
    
    // Otherwise, return the string value (case-insensitive)
    return value.toLowerCase(); // Ensure consistent string comparison
  } else {
    // Handle numeric values (e.g., integers)
    return parseFloat(value); // Return the numeric value directly
  }
};

export const sortData = (data, sortConfig) => {
  return [...data].sort((a, b) => {
    const valueA = getSortValue(a, sortConfig.key);
    const valueB = getSortValue(b, sortConfig.key);

    if (valueA < valueB) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

export const getFullUrl = (path) => {
  const baseUrl = process.env.REACT_APP_API_URL;
  return `${baseUrl}/${path}`;
};

// Function to remove the negative symbol from a value (handles both strings and numbers)
export const removeSign = (value) => {
  // Convert value to a number and return its absolute value if it's not empty
  if (!isEmptyField(value)) {
    return Math.abs(formatNumberWithTwoDecimals(parseFloat(value)));
  }
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "expires=" + date.toUTCString() + "; ";
  }
  document.cookie = `${name}=${value}; ${expires}; path=/`;
}

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length);
  }
  return null;
}

export const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export const downloadFile = (fileUrl) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = ''; // Ensures the original file name is used
  document.body.appendChild(link); // Append to body for compatibility
  link.click(); // Trigger the download
  document.body.removeChild(link); // Clean up after download
};

export const showErrorMessage = (msg) => {
  message.error({
    content: msg || 'Oops! Unable to refresh data. Please try again later.',
    duration: 1, // Duration in seconds
  });
}

export const showSuccessMessage = (msg) => {
  message.success({
    content: msg || 'Success! Your data has been saved.',
    duration: 1, // Duration in seconds
  });
}
