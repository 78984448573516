// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form } from 'react-bootstrap'; // Import Modal, Button, and Form components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees, ConvertDateToMilliseconds, formatDateToInputValue } from '../../utils/helpers';

// Initial form data state
const initialFormData = {
    name: '', // Property name
    address: '', // Property address
    purchase_price: '', // Purchase price of the property
    current_price: '', // Current price of the property
    date: '', // Purchase date of the property
    notes: '', // Additional notes
};

// Define the RealEstateForm functional component
const RealEstateForm = ({ isOpen, closeModal, onFormSubmit, filledData, onEdit }) => {
    // State for form validation, form data, and loading state
    const [validated, setValidated] = useState(false); // Validation state
    const [formData, setFormData] = useState(initialFormData); // Form data state
    const [isFormDirty, setIsFormDirty] = useState(false); // Flag to check if form has been modified
    const [isDateFocused, setIsDateFocused] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false); // Loading state for button
  
    // Effect to reset form data when modal opens
    useEffect(() => {
        if (isOpen) {
            if (filledData) {
                setFormData({
                    ...filledData,
                    purchase_price: formatRupees(filledData.investment),  // Map investment to purchase_price
                    current_price: formatRupees(filledData.curr_val),     // Map curr_val to current_price
                    date: formatDateToInputValue(filledData.date),        // Convert timestamp to input date
                });
            } else {
                setFormData(initialFormData);
            }
            setValidated(false);
            setIsFormDirty(false);
        }
    }, [isOpen, filledData]);
  
    // Handle input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target; // Get input field ID and value
        let newValue = value;

        // If input is for a number field, remove commas for processing
        if (id === 'purchase_price' || id === 'current_price') {
            newValue = value.replace(/,/g, ''); // Remove commas
            newValue = formatRupees(newValue); // Add commas
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true); // Mark form as dirty
    };
  
    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        const form = event.currentTarget; // Get the form element
    
        // Check form validity
        if (form.checkValidity() === false) {
            event.stopPropagation(); // Stop submission if form is invalid
            setValidated(true); // Show validation feedback
        } else {
            if ((!formData.name || !formData.purchase_price || !formData.date)) {
                setValidated(true); // If the form is blank and nothing has been added, show validation error
                setIsButtonLoading(false);
                return;
            }
            setIsButtonLoading(true); // Set button to loading state
            try {
                // Convert date to milliseconds before submitting the form data
                let formDataWithConvertedDate = {
                    ...formData,
                    date: ConvertDateToMilliseconds(formData.date), // Convert date here
                };
                // If onEdit is true, remove curr_val and investment
                if (filledData) {
                    const { curr_val, investment, returns, id, ...rest } = formDataWithConvertedDate; // Destructure to remove curr_val and investment
                    formDataWithConvertedDate = rest;
                }
                await onFormSubmit({ ...formDataWithConvertedDate }, filledData?.id); // Submit form data
            } catch (error) {
                // Handle network or other errors
                console.error('RealEstate Form Error:', error);
            } finally {
                setIsButtonLoading(false); // Set loading state back to false
            }
        }
    };

    // Check if all fields are empty
    const areFieldsEmpty = Object.values(formData).every(value => value === '');
  
    // Handle modal close
    const handleClose = () => {
        // Only ask for confirmation if the form is dirty and not all fields are empty
        if (isFormDirty && !areFieldsEmpty) {
            const confirmClose = window.confirm('Are you sure you want to exit? Unsaved changes will be lost.');
            if (!confirmClose) {
                return; // Prevent closing if user cancels
            }
        }
        closeModal(); // Close the modal
    };

    // Render the form modal
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Property Details</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>
                {/* Form with validation */}
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                        <Form.Control
                            type="text"
                            placeholder="Property Name *"
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the property name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Control
                            type="text"
                            placeholder="Address"
                            value={formData.address}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="purchase_price">
                        <Form.Control
                            type="text"
                            placeholder="Purchase Price *"
                            required
                            value={formData.purchase_price}
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the purchase price.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="current_price">
                        <Form.Control
                            type="text"
                            placeholder="Current Price"
                            value={formData.current_price}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="date">
                        <div className={`position-relative ${!formData.date ? 'placeholder-date' : ''}`}>
                        {!formData.date && !isDateFocused && (
                            <span className='date-placeholder'>
                            Purchase Date *
                            </span>
                        )}
                        <Form.Control
                            type="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            placeholder="Purchase Date" 
                            required
                            style={{
                                width: '100%',
                                cursor: 'pointer',
                            }}
                            onFocus={(e) => {
                                if (!formData.date) {
                                    setIsDateFocused(true); // Only set focus state if date is not filled
                                    e.target.showPicker();  // Show the picker when focused
                                }
                            }}
                            onBlur={() => setIsDateFocused(false)} // Show placeholder on blur if no date
                        />
                        <Form.Control.Feedback type="invalid">
                            Please select the date.
                        </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="notes">
                        <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Notes"
                            value={formData.notes}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="submit" className="btn-container">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isButtonLoading || (onEdit && !formData.name)} // Add condition for loading and edit mode
                        >
                            {isButtonLoading
                                ? (onEdit ? 'Updating...' : 'Saving...')
                                : (onEdit ? 'Update' : 'Save')}
                        </Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default RealEstateForm;
