import React from "react";
import { getFullUrl } from "./utils/helpers";

const Footer = () => {
    return (
        <div className="terms-container">By proceeding, you agree to the
            <span>&nbsp;<a href={getFullUrl('terms-of-services')} target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</a></span>
            &nbsp;and <span><a href={getFullUrl('privacy-policy')} target="_blank" rel="noopener noreferrer">Privacy Policy</a>&nbsp;</span>
            of MoneyLens
        </div>
    )
}

export default Footer;