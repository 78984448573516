import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import '../../css/CompanyInformation.css';
import PrivateEquityTransactionList from './PrivateEquityTransactionList';
import Loader from '../Loader';
import EmptyState from '../EmptyState';
import { getFullUrl, fetchData, updateDirtyState, showErrorMessage } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';
import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
// import { useSidebar } from '../SidebarContext';

function PrivateEquityInformation() {
    const { name } = useParams();

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };
    const location = useLocation();
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true);
    const [information, setInformation] = useState([]);
    const [transactions, setTransactions] = useState([]);

    // const { updateSideBarData } = useSidebar();

    const url = `${apiConfig.PRIVATEEQUITYINFO}/${name}`;
    const method = 'GET';

    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url]);

    const loadData = async () => {
        try {
            trackEvent('PrivateEquity Info', 'request', `Loading data for ${name}`);
            const data = await fetchData(url, method, '', true, true);
            if (data) {
                setInformation(data.header);
                setTransactions(data.transactions);
                trackEvent('PrivateEquity Info', 'Success', `Loaded data for ${name}`);
            } else {
                // Set empty state or handle missing data case
                setInformation([]);
                setTransactions([]);
                trackEvent('PrivateEquity Info', 'Failure', `No data found for ${name}`);
            }
        } catch (error) {
            showErrorMessage();
            trackEvent('PrivateEquity Info', 'Error', `Error loading data for ${name}: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        setIsLoading(true);
        loadData();
        updateDirtyState('privateEquityList');
        updateDirtyState('privateEquityAnalyticsData');
        updateDirtyState('privateEquityTransactionList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();
        
        trackEvent('PrivateEquity Action', 'Refresh', `Refreshed data for ${name}`);
    }

    return (
        <div>
            {isLoading ? ( 
                <Loader />
            ) : transactions.length === 0 ? 
                <EmptyState openModal={false} backeUrl='/assets/privateequity'/>
            : (
                <div className='company-info-container container'>
                    <div className="icon-inner" onClick={() => handleNavigation('/assets/privateequity')} style={{paddingLeft: 'unset', paddingTop: '2rem'}}>
                        <img alt="leftArrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/back-black-icon.png')}/>
                        <div className='module-name' style={{color: '#000000'}}>Back</div>
                    </div>
                    <PrivateEquityTransactionList information={information} transaction={transactions} handleRefresh={handleRefresh}/> 
                </div>
            )}
        </div>
    );
}

export default PrivateEquityInformation;