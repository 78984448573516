import React, { useState, useEffect } from 'react';
import { fetchData } from '../../utils/helpers';
import { message } from 'antd';

import Loader from "../Loader";
import TransactionList from '../TransactionList';
import { apiConfig } from '../../ApiConfig';

function USStocksTransaction() {

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [transactionList, setTransactionList] = useState([]);

  const url = apiConfig.USTRANSACTIONS;
  const method = 'GET';
  const localStorageKey = 'usStocksTransactionList';

  // Fetch asset data when the component mounts
  useEffect(() => {
    loadData();
  }, [url, method, localStorageKey]);

  const loadData = async (refresh = false) => {
      try {
          const data = await fetchData(url, method, localStorageKey, refresh);
          setTransactionList(data.transactions_data)
      } catch (error) {
          console.error(`US Stock Transaction: ${error.message}`);
      } finally {
          setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  return (
    <div>
      {isLoading ? 
        ( <Loader /> ) 
        : (
          <div className="broker-main-container">
            {transactionList.length > 0 ? (
            <TransactionList data={transactionList} module={'usstocks'}/>
          ) : (
            <div className='show-error'>No transactions available.</div> // Message for empty transactions
          )}
          </div>
        )}
    </div>
  );
}

export default USStocksTransaction;
