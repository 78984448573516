import React, { useState, useEffect } from 'react';
import { fetchData } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import Loader from "../Loader";
import TransactionList from '../TransactionList';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';

function PrivateEquityTransactions() {

  const location = useLocation();
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [transactionList, setTransactionList] = useState([]);

  const url = apiConfig.PRIVATEEQUITYTRANSACTIONS;
  const method = 'GET';
  const localStorageKey = 'privateEquityTransactionList';

  // Fetch asset data when the component mounts
  useEffect(() => {
    loadData();
  }, [url, method, localStorageKey]);

  const loadData = async (refresh = false) => {
    try {
      trackEvent('PrivateEquity Transactions', 'request', `Loading private equity transactions data`);
      const data = await fetchData(url, method, localStorageKey, refresh);
      setTransactionList(data.transactions_data || []); // Ensure data is an array
      trackEvent('PrivateEquity Transactions', 'success', `Successfully private equity transactions data`);
    } catch (error) {
      trackEvent('PrivateEquity Transactions', 'error', `APi (${url}): ${error.message}`);
    } finally {
      setIsLoading(false); // Set loading to false after data is fetched or an error occurs
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="broker-main-container">
          {transactionList.length > 0 ? (
            <TransactionList data={transactionList} />
          ) : (
            <div className='show-error'>No transactions available.</div> // Message for empty transactions
          )}
        </div>
      )}
    </div>
  );
}

export default PrivateEquityTransactions;
