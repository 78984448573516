import React, { useState, useEffect } from 'react';
import { fetchData } from '../../utils/helpers';

import Loader from "../Loader";
import TransactionList from '../TransactionList';
import { apiConfig } from '../../ApiConfig';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';


function PreciousMetalsTransaction() {

  const location = useLocation();
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [transactionList, setTransactionList] = useState([]);

  const url = apiConfig.PRECIOUSMETALSTRANSACTIONS;
  const method = 'GET';
  const localStorageKey = 'preciousMetalsTransactionList';

  // Fetch asset data when the component mounts
  useEffect(() => {
    loadData();
  }, [url, method, localStorageKey]);

  const loadData = async (refresh = false) => {
      try {
          trackEvent('PreciousMetals Transactions', 'request', `Loading precious metals transactions data`);
          const data = await fetchData(url, method, localStorageKey, refresh);
          setTransactionList(data.transactions_data)
          trackEvent('PreciousMetals Transactions', 'success', `Successfully precious metals transactions data`);
      } catch (error) {
          console.error(`Precious Metals Transactions: ${error.message}`);
          trackEvent('PreciousMetals Transactions', 'error', `APi (${url}): ${error.message}`);
      } finally {
          setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  return (
    <div>
      {isLoading ? 
        <Loader />
        : (
          <div className="broker-main-container">
            {transactionList.length > 0 ? (
              <TransactionList data={transactionList} module={'preciousmetals'}/>
            ) : (
              <div className='show-error'>No transactions available.</div> // Message for empty transactions
            )}
          </div>
        )
      }
    </div>
  );
}

export default PreciousMetalsTransaction;
