import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const GMAIL_API_URL = 'https://www.googleapis.com/gmail/v1/users/me/messages';
const GMAIL_MESSAGE_URL = 'https://www.googleapis.com/gmail/v1/users/me/messages/';

// Fetch specific Gmail email by ID
const getGmailMessageById = async (accessToken, messageId) => {
  const response = await fetch(`${GMAIL_MESSAGE_URL}${messageId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

// Fetch all messages or specific ones using query parameters like subject, label, etc.
const fetchGmailMessages = async (accessToken, query = '') => {
  const response = await fetch(`${GMAIL_API_URL}?q=${query}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return response.json();
};

function ReadEmail() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageDetails, setMessageDetails] = useState(null);
  const [accessToken, setAccessToken] = useState(null); // Store access token in state

  const SCOPES = ['https://www.googleapis.com/auth/gmail.readonly']; // Define the scope for Gmail

  // Handle login success
  const handleLoginSuccess = (response) => {
    const token = response?.access_token;

    if (token) {
      setAccessToken(token); // Save the token in state
      setLoading(true);
      setError(null);

      // Fetch all messages based on specific criteria (e.g., by subject)
      fetchGmailMessages(token, 'subject:Hello') // Change this query as needed
        .then((data) => {
          setMessages(data.messages || []);
          setLoading(false);
        })
        .catch((err) => {
          console.error('Error fetching Gmail data:', err);
          setError('Failed to fetch Gmail data.');
          setLoading(false);
        });
    }
  };

  // Handle login failure
  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);
    setError('Login failed. Please try again.');
  };

  // Fetch and display specific email details by ID
  const handleFetchMessageDetails = (messageId) => {
    if (accessToken) {
      getGmailMessageById(accessToken, messageId)
        .then((messageData) => {
          setMessageDetails(messageData);
        })
        .catch((err) => {
          console.error('Error fetching message details:', err);
        });
    } else {
      console.log('Access token not available');
    }
  };

  return (
    <div className="container">
      <h4>Google OAuth with Gmail API</h4>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Google Login */}
      <GoogleLogin
        onSuccess={handleLoginSuccess}
        onError={handleLoginFailure}
        scope={SCOPES.join(' ')}
        useOneTap
      />

      {/* Loading State */}
      {loading && <p>Loading...</p>}

      {/* Display list of fetched emails */}
      <div>
        {messages.length > 0 ? (
          <ul>
            {messages.map((message) => (
              <li key={message.id}>
                <button onClick={() => handleFetchMessageDetails(message.id)}>
                  View Message
                </button>
                Message ID: {message.id}
              </li>
            ))}
          </ul>
        ) : (
          <p>No messages found based on the query.</p>
        )}
      </div>

      {/* Display details of a specific message */}
      {messageDetails && (
        <div>
          <h2>Message Details:</h2>
          <pre>{JSON.stringify(messageDetails, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

function AppWithGoogleOAuth() {
    const client_ID = '996795173041-6k0dhmrbaomtp46fid4jbeg9td0vqg1n.apps.googleusercontent.com'
  return (
    <GoogleOAuthProvider clientId={client_ID}>
        <ReadEmail />
    </GoogleOAuthProvider>
  );
}

export default AppWithGoogleOAuth;
