import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import BankFdsAnalytics from './BankFdsAnalytics';
import BankFdsTransaction from './BankFdsTransaction';
import Loader from "../Loader";

import { fetchData, updateDirtyState, apiRequest } from "../../utils/helpers";
import { message } from "antd";
import BankFdsForm from "./BankFdsForm";
import EmptyState from "../EmptyState";
import { apiConfig } from "../../ApiConfig";
import ComingSoon from "../ComingSoon";

function BankFds() {
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [bankFdsList, setBankFdsList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    const url = '';
    const method = 'GET';
    const localStorageKey = 'bankFdsList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        //loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(url, method, localStorageKey, refresh);
            if (!data) {
                setPortfolioValue(null);
                setBankFdsList([]);
                setPortfolioValue1DChange(null);
                setPortfolioValueReturns(null);
            }else {
                // Set state with actual data
                setPortfolioValue(data);
                setBankFdsList(data.asset_details || []);
                setPortfolioValue1DChange(data['1d_change'] || [0, 0]);
                setPortfolioValueReturns(data['returns'] || [0, 0]);
            }
        } catch (error) {
            message.error({
                content: `Error fetching ind stock list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('bankFdsList');
        updateDirtyState('bankFdsAnalyticsData');
        updateDirtyState('bankFdsTransactionList');
    };

    const portfolioData = {
        module: 'bankfds',
        value: portfolioValue?.curr_val || 0,  // Use optional chaining and a fallback value
        dayChangeValue: portfolioValue1DChange?.[0] || 0,  // Optional chaining with fallback
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };    
    
    const tabs = [
        { key: 'stocklist', name: 'My Funds', component: StockList },
        { key: 'analytics', name: 'Analytics', component: BankFdsAnalytics },
        { key: 'transactions', name: 'Transactions', component: BankFdsTransaction },
    ];
    
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (formData) => {
        formData = { transactions: formData };
        console.log(formData)
        // try {
        //     const url = apiConfig.USTRANSACTIONS; // Replace with your actual API URL
            
        //     // Call the API to post the form data
        //     const result = await apiRequest(url, 'POST', formData);
        //     if(result.statusCode === 200){
        //         handleRefresh();
        //         closeModal(); // Close the modal after submission
        //     }else {
        //         message.error({
        //             content: `Failed to submit form: ${result.message}`,
        //             duration: 3, // Duration in seconds
        //         });
        //     }
        // } catch (error) {
        //     message.error({
        //         content: `Failed to submit form: ${error.message}`,
        //         duration: 3, // Duration in seconds
        //     });
        //     // Handle error (e.g., show a notification to the user)
        // }
    };

    const initialFormData = {
        depositedin: '',
        organisationname: '',
        amountinvested: '', 
        annualrate: '',
        startdate: '',
        enddate: '',
        compoundingfrequency: '', 
    };
    const heading = 'Coming Soon: A Holistic View of Your Banking World';
    const paraFirst = 'We’re excited to introduce a new feature that will bring all your banking and fixed deposit information together in one place!';
    const paraSecond = 'Soon, you’ll be able to seamlessly track your bank accounts, FDs, and view a comprehensive picture of all your investments across different asset classes.';
    const paraThird = 'Get ready for a smarter way to manage, monitor, and maximize your wealth—all in one secure platform. Stay tuned!';
    return (
        // <div>
        //     {isLoading ? ( 
        //         <Loader />
        //     ) : bankFdsList.length === 0 ? ( // Check if stock list is empty
        //         <EmptyState openModal={openModal}/>  // Render the Empty State component when there's no data
        //     ) : (
        //         <div className="container indStock pad-right-unset pad-left-unset">
        //             <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'FDs'} openForm={() => openModal()}/>
        //             <DynamicTabs tabs={tabs} data={bankFdsList} module={'bankfds'}/>
                    
        //             <div className="float-container">
        //                 <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
        //                     <i className="fa fa-plus my-float"></i>
        //                 </a>
        //             </div>
        //         </div>
        //     )}
        //     <BankFdsForm 
        //         isOpen={isModalOpen} 
        //         closeModal={closeModal} 
        //         onFormSubmit={handleFormSubmit} 
        //         initialFormData={initialFormData}
        //         defaultForm='manual'
        //     />
        // </div>
        <ComingSoon heading={heading} paraFirst={paraFirst} paraSecond={paraSecond} paraThird={paraThird}/>
    );
}

export default BankFds;