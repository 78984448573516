import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import '../css/MutualFundAnalytics.css';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { FaGripLines } from 'react-icons/fa';

function Analytics ({initialRows, contentMap, module}) {
  // Render based on `contentId`
  const renderContent = (contentId) => contentMap[contentId] || null;
  // Load from localStorage or use the initial rows
  const [rows, setRows] = useState(() => {
    const cachedRows = localStorage.getItem(`${module}-row`);
    return cachedRows ? JSON.parse(cachedRows) : initialRows;
  });
  const handleDragStart = (e, rowId) => {
    e.dataTransfer.setData('text/plain', rowId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetRowId) => {
    const sourceRowId = e.dataTransfer.getData('text/plain');
    if (!sourceRowId) return;

    // Find the source and target rows
    const sourceRowIndex = rows.findIndex((row) => row.id === sourceRowId);
    const targetRowIndex = rows.findIndex((row) => row.id === targetRowId);

    if (sourceRowIndex === -1 || targetRowIndex === -1) return;

    // Reorder rows
    const newRows = [...rows];
    const [movedRow] = newRows.splice(sourceRowIndex, 1);
    newRows.splice(targetRowIndex, 0, movedRow);

    setRows(newRows);
    localStorage.setItem(`${module}-row`, JSON.stringify(newRows)); // Save to localStorage
  };

  useEffect(() => {
    // Load cached rows on component mount
    const cachedRows = localStorage.getItem('analyticsRows');
    if (cachedRows) {
      setRows(JSON.parse(cachedRows));
    }
  }, []);
  return (
    <Container fluid className='broker-main-container analytics'>
      {rows.map((row) => (
        <Row key={row.id} className='piechart-container'
        draggable
        onDragStart={(e) => handleDragStart(e, row.id)}
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, row.id)}
        >
          {row.columns.map((item) => (
            <Col key={item.id}>
                {item.contentId && (
                    <Accordion defaultActiveKey={renderContent(item.contentId) ? item.id : null}>
                        <Accordion.Item eventKey={item.id}>
                          <Accordion.Header>
                              {item.header}
                              <FaGripLines
                              draggable
                              onDragStart={(e) => handleDragStart(e, row.id)}
                              className="drag-icon"
                              style={{ cursor: 'grab' }}
                              />
                          </Accordion.Header>
                          <Accordion.Body>
                            {renderContent(item.contentId) ? (
                              renderContent(item.contentId)
                            ) : (
                              <div className={`empty-message piechart-wrapper ${
                                item.contentId === 'trending-stocks-losers' || item.contentId === 'trending-stocks-gainers'
                                  ? 'trendingStocks-empty'
                                  : 'piechart-empty'
                              }`}>No data available</div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                )}
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
}

export default Analytics;