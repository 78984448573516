import ReactGA from "react-ga4";

// Initialize GA
export const initGA = () => {
  console.log(`######:${process.env.NODE_ENV}, ${process.env.REACT_APP_VERSION}`)
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize("G-F6E3E81XZ3");
  }
};

// Track page views
export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Track events
export const trackEvent = (category, action, label) => {
  ReactGA.event({ category, action, label });
};

// Set user info
export const setUserInfo = (email) => {
  ReactGA.set({ user_id: email });
};
