import React, { useState } from 'react';

const SliderWithInput = ({
    onChange,
    label,
    inputLabel,
    defaultValue = 0,
    min = 0,
    max = 100,
    step = 1,
    allowDecimal = false, // New prop to allow decimals
}) => {
    const [value, setValue] = useState(defaultValue); // Initial value

    const handleSliderChange = (e) => {
        const newValue = parseFloat(e.target.value);
        setValue(newValue); // Update value based on slider
        onChange(newValue); // Notify main component of value change
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value; // Keep it as a string for now
    
        // Allow empty input or validate based on min/max and allowDecimal conditions
        if (inputValue === '' || (!isNaN(inputValue) && inputValue >= min && inputValue <= max)) {
            // Check if input is valid numeric input, respecting decimals
            if (allowDecimal || inputValue % 1 === 0) {
                setValue(inputValue); // Update state with the raw string value
                if (inputValue !== '') {
                    onChange(parseFloat(inputValue)); // Notify main component with a parsed number when not empty
                } else {
                    onChange(''); // Optional: Handle empty state
                }
            }
        }
    };
    

    // Calculate the percentage of the value relative to the max value
    const percentage = Math.min((value - min) / (max - min) * 100, 100); // Ensure the percentage does not exceed 100

    return (
        <div className="slider-bar-container">
            <label className="slider-label">{label}</label>
            <div className="slider-input-container">
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    step={allowDecimal ? 0.5 : step} // Set step based on allowDecimal
                    onChange={handleSliderChange}
                    className="custom-slider"
                    style={{
                        background: `linear-gradient(to right, #002D62 ${percentage}%, #d3d3d3 ${percentage}%)`, // Fill color
                    }}
                />
                <div className="slider-input-group">
                    <input
                        type="number"
                        value={value}
                        onChange={handleInputChange}
                        className="slider-input"
                        min={min}
                        max={max}
                        step={allowDecimal ? 0.5 : ''} // Allow input to be in increments based on allowDecimal
                    />
                    <span className="input-suffix">{inputLabel}</span>
                </div>
            </div>
        </div>
    );
};

export default SliderWithInput;
