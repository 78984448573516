import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import RetirementFundsAnalytics from './RetirementFundsAnalytics';
import RetirementFundsTransaction from './RetirementFundsTransaction';
import Loader from "../Loader";

import { fetchData, updateDirtyState, showErrorMessage } from "../../utils/helpers";
import RetirementFundsForm from "./RetirementFundsForm";
import EmptyState from "../EmptyState";
import { apiConfig } from "../../ApiConfig";
import ComingSoon from "../ComingSoon";

function RetirementFunds() {
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [retirementFundList, setRetirementFundList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    const url = '';
    const method = 'GET';
    const localStorageKey = 'retirementFundList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        //loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(url, method, localStorageKey, refresh);
            if (!data) {
                setPortfolioValue(null);
                setRetirementFundList([]);
                setPortfolioValue1DChange(null);
                setPortfolioValueReturns(null);
            }else {
                // Set state with actual data
                setPortfolioValue(data);
                setRetirementFundList(data.asset_details || []);
                setPortfolioValue1DChange(data['1d_change'] || [0, 0]);
                setPortfolioValueReturns(data['returns'] || [0, 0]);
            }
        } catch (error) {
            showErrorMessage();
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('retirementFundList');
        updateDirtyState('retirementFundAnalyticsData');
        updateDirtyState('retirementFundTransactionList');
    };

    const portfolioData = {
        module: 'retirementfunds',
        value: portfolioValue?.curr_val || 0,  // Use optional chaining and a fallback value
        dayChangeValue: portfolioValue1DChange?.[0] || 0,  // Optional chaining with fallback
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };    
    
    const tabs = [
        { key: 'stocklist', name: 'My Funds', component: StockList },
        { key: 'analytics', name: 'Analytics', component: RetirementFundsAnalytics },
        { key: 'transactions', name: 'Transactions', component: RetirementFundsTransaction },
    ];
    
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (formData) => {
        formData = { transactions: formData };
        console.log(formData)
        // try {
        //     const url = apiConfig.USTRANSACTIONS; // Replace with your actual API URL
            
        //     // Call the API to post the form data
        //     const result = await apiRequest(url, 'POST', formData);
        //     if(result.statusCode === 200){
        //         handleRefresh();
        //         closeModal(); // Close the modal after submission
        //     }else {
        //         message.error({
        //             content: `Failed to submit form: ${result.message}`,
        //             duration: 3, // Duration in seconds
        //         });
        //     }
        // } catch (error) {
        //     message.error({
        //         content: `Failed to submit form: ${error.message}`,
        //         duration: 3, // Duration in seconds
        //     });
        //     // Handle error (e.g., show a notification to the user)
        // }
    };

    const initialFormData = {
        amountinvested: '', // Purchase price
        date: '', // Purchase date
    };
    const heading = 'Coming Soon: Retirement Funds Tracker!';
    const paraFirst = 'Prepare to gain clarity on your path to retirement! Our new Retirement Funds Tracker will let you monitor all your retirement investments, including NPS, EPF, and PPF, in one convenient dashboard.';
    const paraSecond = ' You’ll be able to see how these funds have grown over time and explore projections for the years ahead to manage your long-term savings effortlessly!';
    const paraThird = 'Stay tuned for insights that empower you to plan smarter, invest with confidence, and secure your financial future!';
    return (
        // <div>
        //     {isLoading ? ( 
        //         <Loader />
        //     ) : retirementFundList.length === 0 ? ( // Check if stock list is empty
        //         <EmptyState openModal={openModal}/>  // Render the Empty State component when there's no data
        //     ) : (
        //         <div className="container indStock pad-right-unset pad-left-unset">
        //             <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'Retirement Funds'} openForm={() => openModal()}/>
        //             <DynamicTabs tabs={tabs} data={retirementFundList} module={'usstocks'}/>
                    
        //             <div className="float-container">
        //                 <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
        //                     <i className="fa fa-plus my-float"></i>
        //                 </a>
        //             </div>
        //         </div>
        //     )}
        //     <RetirementFundsForm 
        //         isOpen={isModalOpen} 
        //         closeModal={closeModal} 
        //         onFormSubmit={handleFormSubmit} 
        //         initialFormData={initialFormData}
        //         defaultForm='manual'
        //     />
        // </div>
        <ComingSoon heading={heading} paraFirst={paraFirst} paraSecond={paraSecond} paraThird={paraThird}/>
    );
}

export default RetirementFunds;