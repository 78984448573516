import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { apiConfig } from '../../ApiConfig';
import { fetchData, shortFormatRupees, isNegative, updateDirtyState, getFullUrl, removeSign, formatNumberWithTwoDecimals, showErrorMessage } from '../../utils/helpers';
import '../../css/CompanyInformation.css';

import Loader from '../Loader';
import EmptyState from '../EmptyState';
import ApexChart from '../ApexChart';
import IndiaStockIndividualTransactionList from './IndiaStockIndividualTransactionList';
import IndiaStockCompanyInformation from './IndiaStockCompanyInformation';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';

// import { useSidebar } from '../SidebarContext';

function IndiaStocksInformation() {
    const { id } = useParams();
    const location = useLocation();
    const [pageInfo, setPageInfo] = useState('');

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true);
    const [information, setInformation] = useState([]);
    const [info1DayChange, setInfo1DayChange] = useState([]);
    const [baseTrend, setBaseTrend] = useState([]);
    const [referenceTrend, setReferenceTrend] = useState([]);
    const [insights, setInsights] = useState([]);
    const [transactions, setTransactions] = useState([]);

    // const { updateSideBarData } = useSidebar();

    const url = `${apiConfig.INDIASTOCKINFO}/${id}`;
    const method = 'GET';

    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url]);

    useEffect(() => {
        const parts = location.pathname.split('/').filter(Boolean);
        const pageInfo = parts[parts.length - 3];
        setPageInfo(pageInfo);
    }, [location.pathname]);

    const loadData = async () => {
        try {
            trackEvent('IndiaStocks Info', 'request', `Loading data for ${id}`);
            const data = await fetchData(url, method, '', true, true);
            if (data) {
                setInformation(data.header);
                setInfo1DayChange(data.header['1d_change']);
                setBaseTrend(data.historic_trend.base_trend);
                setReferenceTrend(data.historic_trend.reference_trend);
                setInsights(data.insights);
                setTransactions(data.transactions);
                trackEvent('IndiaStocks Info', 'Success', `Loaded data for ${id}`);
            } else {
                // Set empty state or handle missing data case
                setInformation([]);
                setInfo1DayChange([]);
                setBaseTrend([]);
                setReferenceTrend([]);
                setInsights([]);
                setTransactions([]);
                trackEvent('IndiaStocks Info', 'Failure', `No data found for ${id}`);
            }
        } catch (error) {
            showErrorMessage();
            console.error(`IndiaStocks Info: ${error.message}`);
            trackEvent('IndiaStocks Info', 'Error', `Error loading data for ${id}: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRefresh = () => {
        setIsLoading(true);
        loadData();
        updateDirtyState('indiaStockList');
        updateDirtyState('indiaStocksAnalyticsData');
        updateDirtyState('indiaStocksTransactionList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();
        
        trackEvent('IndiaStocks Action', 'Refresh', `Refreshed data for ${id}`);
    }
    const returnsPercentage = isNegative(parseInt(info1DayChange[0]));
    const profitLossSymbol = (returnsPercentage) ? '' : '+';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';
    const rotateClass = (returnsPercentage) ? 'rotate-text' : '';
    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : transactions.length === 0 ? (
                <EmptyState openModal={false} backeUrl='/assets/indiastocks'/>
            ) : (
                <div className='company-info-container container'>
                    <div className="icon-inner" onClick={() => handleNavigation('/assets/indiastocks')} style={{paddingLeft: 'unset'}}>
                        <img alt="leftArrow" loading="lazy" width="24" height="24" decoding="async" data-nimg="1" src={getFullUrl('images/back-black-icon.png')}/>
                        <div className='module-name' style={{color: '#000000'}}>Back</div>
                    </div>
                    <div className="company-info-heading-container">
                        <div className='company-info-heading-img-container'>
                            <img
                                alt=""
                                loading="lazy"
                                width="75"
                                height="75"
                                decoding="async"
                                src={information.logo}
                                style={{ color: 'transparent' }}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop in case the fallback also fails
                                    e.target.style.display = 'none'; // Removes image space when the image fails to load
                                }}
                            />
                        </div>
                        <div className="company-info-heading-name-container">
                            <h3>{information.name}</h3>
                            <div className='company-info-heading-price-container'>
                                <span className="this-fund-col">
                                    Current Value:&nbsp;
                                    <span className="company-info-heading-price">
                                        ₹{shortFormatRupees(information.curr_val)} (Qty: {Math.round(information.quantity)}, NAV: {formatNumberWithTwoDecimals(information.curr_price)})
                                    </span>
                                </span>
                                <span className="this-fund-col">
                                    1 Day Change:&nbsp;
                                    <span className="company-info-graph-profit-number text-bold">₹{shortFormatRupees(info1DayChange[0])}&nbsp;&nbsp;
                                        <span className={classNames('', profitLossClass)}>
                                            <span className={classNames('', rotateClass)}>▲</span>
                                            {removeSign(info1DayChange[1])}%
                                        </span>
                                    </span>
                                </span>
                                {/* <span>1 Day Change: ₹{shortFormatRupees(info1DayChange[0])}</span> 
                                <span className={classNames('broker-cell-account returns-col', profitLossClass)}><span>({profitLossSymbol}{info1DayChange[1]}%)</span></span> */}
                            </div>
                        </div>
                    </div>
                    <div className='trend-container'>
                        <ApexChart
                            baseTrend={baseTrend.daily_data}
                            referenceTrend={referenceTrend.daily_data}
                            baseWeeklyTrend={baseTrend.weekly_data}
                            referenceWeeklyTrend={referenceTrend.weekly_data}
                            referenceIndex={referenceTrend.index}
                            baseIndex='Stock Price'
                        />
                    </div>
                    <IndiaStockCompanyInformation info={information} insights={insights}/>
                    <IndiaStockIndividualTransactionList transaction={transactions} stockId={id} handleRefresh={handleRefresh}/>
                </div>
            )}
        </div>
    );
}

export default IndiaStocksInformation;
