import React, { useState, useRef, useEffect } from "react";
import { Header } from "./Navbar.style";
import { CDBNavbar } from "cdbreact";
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from "../../AuthContext";
import { getFullUrl, apiRequest } from "../../utils/helpers";
import { apiConfig } from "../../ApiConfig";
import ChangePassword from "../ChangePassword";

const Navbar = ({toggleSidebar}) => {
    const { logout } = useAuth();  // Authentication status
    // State to track if the menu is active or not
    const [isActive, setIsActive] = useState(false);
    // State to track if the sub-menu is active or not
    const [isSubActive, setIsSubActive] = useState(false);
    // State to track if the profile is active or not
    const [isProfileActive, setIsProfileActive] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isEditing, setIsEditing] = useState(false); // Manage edit mode
    const [editName, setEditName] = useState(name);    // Local state to hold the name temporarily
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

    const nameRef = useRef(null); // Reference to the contenteditable div
    // Refs for the menu and profile components
    const menuRef = useRef(null);
    const profileRef = useRef(null);

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsActive(prev => !prev);
        setIsProfileActive(false); // Close profile if menu is toggled
    };

    // Function to toggle the sub-menu
    const toggleSubMenu = () => {
        setIsSubActive(prev => !prev);
    };

    // Function to toggle the profile
    const toggleProfile = () => {
        setIsProfileActive(prev => !prev);
        setIsActive(false); // Close menu if profile is toggled
    };

    // Function to handle clicks outside of the menu and profile
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) && 
            profileRef.current && !profileRef.current.contains(event.target)) {
            setIsActive(false);
            setIsProfileActive(false);
            setEditName(name);
            setIsEditing(false);
            setError(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        setName(userInfo.name);
        setEmail(userInfo.email);
    })

    const handleEditClick = () => {
        setIsEditing(true);
        setEditName(name);
        // Focus and place cursor at the end of text
        setTimeout(() => {
            const range = document.createRange();
            const selection = window.getSelection();
            range.selectNodeContents(nameRef.current);
            range.collapse(false); // Collapse range to end of content
            selection.removeAllRanges();
            selection.addRange(range);
            nameRef.current.focus();
        }, 0);
    };

    const handleSaveClick = async () => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        
        if (editName === name) {
            setIsEditing(false); // Exit edit mode
            setError(null);
            return;
        }
        if (editName.trim() === "") {
            // If name is blank or hasn't changed
            setError("Please enter a username.");
            nameRef.current.focus();
            return;
        }
        try {
            const formData = {
                email: userInfo.email,
                name: editName
            };
        
            // Call the API to post the form data
            const result = await apiRequest(apiConfig.UPDATEPROFILE, 'POST', formData);
            
            if (result.statusCode === 200) {
                setError(null);
                setName(editName);
                setIsEditing(false); // Exit edit mode

                // Update name in localStorage
                userInfo.name = editName;
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
            } else if (result.statusCode === 401 || result.statusCode === 400) {
                setError('Something went wrong, please try again.');
            }
        } catch (error) {
            // Display the API error message
            setError('Something went wrong, please try again.');
        }     
    };

    const handleNameChange = (e) => {
        setEditName(e.target.innerText); // Update editName state with the new content
    };

    // Function to handle opening the modal
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    // Function to handle closing the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditName(name);
        setIsEditing(false);
        setError(null);
    };

    return (
        <Header className="header-container">
            <div className="form-inline ml-auto">
                {/* <span className='toggle-bar'>
                    <i className="fa fa-bars fa-large" onClick={toggleSidebar}></i>
                    <span className="navbar-brand text-primary mr-0">MoneyLens</span>
                </span> */}
                <div className='logo-container'>
                    <i className="fa fa-bars fa-large" onClick={toggleSidebar}></i>
                    <img alt="icon" src={getFullUrl('logo.png')} style={{ width: "2rem", height: "2rem"}} />
                    <div className='logo-name'>MoneyLens</div>
                </div>
            </div>
            <CDBNavbar dark expand="md" scrolling className="justify-content-end">
                <div className="ml-auto">
                    <div className="menu-container">
                        <NavLink exact='true' to="/networth" activeclassname="activeClicked">NetWorth</NavLink>
                        <NavLink exact='true' to="/assets" activeclassname="activeClicked">Assets</NavLink>
                        <NavLink exact='true' to="/debts" activeclassname="activeClicked">Debts</NavLink>
                        {/* Calculator Dropdown */}
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-basic" className="custom-dropdown-toggle">
                            Calculators <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="custom-dropdown-menu">
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/growth" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    CAGR Calculator
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/retirement" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    Retirement Planning
                                </Dropdown.Item>
                                <Dropdown.Item as={NavLink} exact="true" to="/calculator/sip" target="_blank" className="custom-item" activeclassname="activeClicked">
                                    SIP Calculator
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{ marginLeft: '15px', cursor: 'pointer', textTransform: 'capitalize' }} onClick={toggleProfile}>
                        {name}
                            <i className="fas fa-angle-down" style={{ marginLeft: '7px' }}></i>
                        </span>
                        <img alt="profileImage" src={getFullUrl('images/pane4.png')} style={{ width: "3rem", height: "3rem", cursor: 'pointer' }} onClick={toggleProfile} />
                    </div>
                </div>
                <div ref={menuRef} className={`menu ${isActive ? 'active' : ''}`}>
                    <ul>
                        <li>
                            <a className="active" style={{textTransform: 'capitalize'}}>{name}</a>
                            <button className="options" onClick={toggleSubMenu}></button>
                            <div className={`submenu ${isSubActive ? 'active' : ''}`}>
                                <ul>
                                    <li><a className="">Rename Portfolio</a></li>
                                    <li className="newPSection"><a href="#">Delete</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="newPSection"><a href="#">+ New Portfolio</a></li>
                    </ul>
                </div>
                <div ref={profileRef} className={`profile ${isProfileActive ? 'active' : ''}`}>
                    <div className="profile-section">
                        <img alt="profileImage" src={getFullUrl('images/pane4.png')} style={{ width: "3rem", height: "3rem" }} />
                        <div className="profile-name">
                        <div
                            contentEditable={isEditing}
                            ref={nameRef}
                            onInput={handleNameChange}
                            suppressContentEditableWarning={true}
                            style={{
                                border: isEditing ? '1px solid #ccc' : 'none',
                                padding: '.5rem',
                                outline: 'none',
                                display: 'inline'
                            }}
                        >
                            {name}
                        </div>
                        {isEditing ? (
                            <i className="fas fa-save" onClick={handleSaveClick} style={{ cursor: 'pointer', marginLeft: '5px' }}></i>
                        ) : (
                            <i className="fas fa-edit" onClick={handleEditClick} style={{ cursor: 'pointer', marginLeft: '5px' }}></i>
                        )}
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <div className="profile-info">{email}</div>
                    </div>
                    <div className="newPSection">
                        <ul>
                            <li><a className="active" onClick={handleOpenModal}>Change Password</a></li>
                            <li onClick={logout}><a>Sign Out</a></li>
                        </ul>
                        {isModalOpen && <ChangePassword onClose={handleCloseModal} />}
                    </div>
                </div>
            </CDBNavbar>
        </Header>
    );
};

export default Navbar;
