import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import IndiaStocksAnalytics from './IndiaStocksAnalytics';
// import IndiaStocksTransactions from './IndiaStocksTransactions';
import Loader from "../Loader";
import EmptyState from "../EmptyState";

import { fetchData, updateDirtyState, apiRequest, showErrorMessage, showSuccessMessage } from "../../utils/helpers";

import { apiConfig } from "../../ApiConfig";
import IndiaStocksForm from "./IndiaStocksForm";

import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";

// import { useSidebar } from "../SidebarContext";

function IndiaStocks() {

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [indiaStockList, setIndiaStockList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    const [isModalOpen, setIsModalOpen] = useState(false);

    // const { updateSideBarData } = useSidebar();

    const url = apiConfig.INDIASTOCKSUMMARY;
    const method = 'GET';
    const localStorageKey = 'indiaStockList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('IndiaStocks', 'request', 'Loading india stocks data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            if(data) {
                setPortfolioValue(data);
                setIndiaStockList(data.asset_details)
                setPortfolioValue1DChange(data['1d_change'])
                setPortfolioValueReturns(data['returns'])
                trackEvent('IndiaStocks', 'success', 'Successfully loaded india stocks data');
            }
        } catch (error) {
            showErrorMessage();
            console.error(`Indiastock list: ${error.message}`);
            trackEvent('IndiaStocks', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('indiaStockList');
        updateDirtyState('indiaStocksAnalyticsData');
        updateDirtyState('indiaStocksTransactionList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();
    };

    const portfolioData = {
        module: 'indiastocks',
        value: portfolioValue?.curr_val || 0,
        dayChangeValue: portfolioValue1DChange?.[0] || 0,
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };
    
    const tabs = [
        { key: 'stocklist', name: 'My Stocks', component: StockList },
        { key: 'analytics', name: 'Analytics', component: IndiaStocksAnalytics },
        // { key: 'transactions', name: 'Transactions', component: IndiaStocksTransactions },
    ];

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
        trackEvent('IndiaStocks', 'openForm', 'Opening form modal');
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (requestData, isFile = false) => {
        let url = apiConfig.INDIASTOCKTRANSACTIONS;
        let formData = { transactions: requestData };

        if (isFile) {
            const fileData = new FormData();
            fileData.append("file", requestData);
            formData = fileData;
        }
        try {
            trackEvent('IndiaStocks', 'request', 'Submitting india stocks transaction');
            // Call the API to post the form data
            const result = await apiRequest(url, 'POST', formData);
            if (result.statusCode === 200){
                showSuccessMessage();
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('IndiaStocks', 'success', 'Successfully submitted transaction');
            } if (result.error) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('IndiaStocks', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('IndiaStocks', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('IndiaStocks', 'error', `APi (${url}): ${error.message}`);
        }
    };

    const initialFormData = {
        id: '', //  id
        quantity: '', // Quantity
        stockprice: '', // Purchase price
        date: '', // Purchase date
    };
    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : indiaStockList.length === 0 ? 
            <EmptyState openModal={openModal}/>
        : (
            <div className="container indiaStock pad-right-unset pad-left-unset">
                <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'India Stocks'} openForm={() => openModal()}/>
                <DynamicTabs tabs={tabs} data={indiaStockList} module={'indiastocks'} onTabChange={(activeTabKey) => {
                    if (activeTabKey === 'stocklist') {
                        loadData(); // Call loadData when "My Stocks" tab is clicked
                    }
                }}/>

                <div className="float-container">
                    <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
                        <i className="fa fa-plus my-float"></i>
                    </a>
                </div>
            </div>
        )}
        <IndiaStocksForm 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            onFormSubmit={handleFormSubmit} 
            initialFormData={initialFormData}
            defaultForm='manual'
        />
        </div>
    );
}

export default IndiaStocks;