import React, {useState, useEffect} from "react";
import '../../css/MutualFund.css';

import TotalPortfolioValue from "../TotalPortfolioValue";
import DynamicTabs from "../DynamicTabs";
import StockList from "../StockList";
import CryptoAnalytics from './CryptoCurrencyAnalytics';
import CryptoTransactions from './CryptoCurrencyTransactions';
import Loader from "../Loader";
import EmptyState from "../EmptyState";

import { fetchData, updateDirtyState, apiRequest, showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import CryptoCurrencyForm from "./CryptoCurrencyForm";

import { apiConfig } from "../../ApiConfig";

import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";
// import { useSidebar } from "../SidebarContext";

function CryptoCurrency() {

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [cryptoCurrencyList, setCryptoCurrencyList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const { updateSideBarData } = useSidebar();

    const url = apiConfig.CRYPTOCURRENCYSUMMARY;
    const method = 'GET';
    const localStorageKey = 'cryptoCurrencyList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('CryptoCurrency', 'request', 'Loading crypto currency data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            setPortfolioValue(data);
            setCryptoCurrencyList(data.asset_details);
            setPortfolioValue1DChange(data['1d_change']);
            setPortfolioValueReturns(data['returns']);
            trackEvent('CryptoCurrency', 'success', 'Successfully loaded crypto currency data');
        } catch (error) {
            showErrorMessage();
            console.error(`CryptoCurrency list: ${error.message}`);
            trackEvent('CryptoCurrency', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        updateDirtyState('cryptoCurrencyList');
        updateDirtyState('cryptoCurrencyAnalyticsData');
        updateDirtyState('cryptoCurrencyTransactionList');
        updateDirtyState('networth-dashboard');
        // updateSideBarData();
    };

    const portfolioData = {
        module: 'cryptocurrency',
        value: portfolioValue?.curr_val || 0,  // Use optional chaining and a fallback value
        dayChangeValue: portfolioValue1DChange?.[0] || 0,  // Optional chaining with fallback
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };
    
    const tabs = [
        { key: 'currencylist', name: 'Crypto Assets', component: StockList },
        { key: 'analytics', name: 'Analytics', component: CryptoAnalytics },
        { key: 'transactions', name: 'Transactions', component: CryptoTransactions },
    ];
    // Initial form data state
    const initialFormData = {
        quantity: '', // Quantity
        cryptoprice: '', // Purchase price
        date: '', // Purchase date
        type: 'buy', // Transaction type: buy or sell
    };
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
        trackEvent('CryptoCurrency', 'openForm', 'Opening form modal');
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (formData) => {
        try {
            trackEvent('CryptoCurrency', 'request', 'Submitting crypto currency transaction');
            const url = apiConfig.CRYPTOCURRENCYTRANSACTIONS; // Replace with your actual API URL
            
            // Call the API to post the form data
            const result = await apiRequest(url, 'POST', formData);
            if (result.statusCode === 200){
                showSuccessMessage();
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('CryptoCurrency', 'success', 'Successfully submitted transaction');
            } if (result.error) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('CryptoCurrency', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                showErrorMessage('Oops! Something went wrong. Please try again later.');
                trackEvent('CryptoCurrency', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            showErrorMessage('Oops! Something went wrong. Please try again later.');
            trackEvent('CryptoCurrency', 'error', `APi (${url}): ${error.message}`);
        }
    };

    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : cryptoCurrencyList.length === 0 ? ( // Check if stock list is empty
            <EmptyState openModal={openModal}/>  // Render the Empty State component when there's no data
        ) : (
            <div className="container cryptocurrency pad-right-unset pad-left-unset">
                <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'Crypto Currency'} openForm={() => openModal(null)}/>
                <DynamicTabs tabs={tabs} data={cryptoCurrencyList} module={'cryptocurrency'} onTabChange={(activeTabKey) => {
                    if (activeTabKey === 'currencylist') {
                        loadData(); // Call loadData when "Crypto Assets" tab is clicked
                    }
                }}/>
                
                <div className="float-container">
                    <a className="float"  onClick={(e) => { e.preventDefault(); openModal(null); }}>
                        <i className="fa fa-plus my-float"></i>
                    </a>
                </div>
            </div>
        )}
        <CryptoCurrencyForm 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            onFormSubmit={handleFormSubmit} 
            initialFormData={initialFormData}
            onEdit={false}
        />
        </div>
    );
}

export default CryptoCurrency;